const settings = {
  maxWidthSetting: '1000px',

  tokenExpirationTime: 3600 * 1000 *23.5, // millisecundy  1 hour = 3600 * 1000)

 
  // Function to get valid token from localStorage
  getToken: () => {
    const storedToken = localStorage.getItem('token');
  
    if (storedToken) {
      const expirationTime = localStorage.getItem('tokenExpiration');
      console.log(expirationTime)
      if (expirationTime && parseInt(expirationTime) > Date.now()) {
        return storedToken; // Valid token
      } else {
        settings.clearToken(); // Token expired, clear it
        return null;
      }
    }
    return null; // No token stored
  },
  // Function to clear token from localStorage
  clearToken: () => {
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('token');
    localStorage.removeItem('access');
    localStorage.removeItem('username');
    localStorage.removeItem('userId');

  }
};

export default settings;