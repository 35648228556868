import { useTheme } from '@emotion/react';
import { Lock, LockOpen, Search, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, Stack, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BottomNavigationMenu from './BottomNavigationMenu';
import settings from './Settings';

const {maxWidthSetting} = settings

const StyledBox = styled(Box) (({ theme }) => ({
  margin: '5px auto',
  padding: '8px',
  fontSize: '12px',
  color: `${theme.palette.secondary.main}`,
  width: '90%',
  maxWidth: `calc(${maxWidthSetting} - 10%)`,
  height: '30px',
  //boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', // Adding some shadow,
  borderBottom: `1px solid ${theme.palette.secondary.light}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px'

}));

const StyledIconButton = styled(IconButton) (({ theme }) => ({
  fontSize: '12px',
  color: `${theme.palette.secondary.main}`,
}));

const StatusPretekov = (token) => {

  const [listOfCups, setListOfCups] = useState()
  const [filteredData, setFilteredData] = useState([])
  const [onSearch, setOnSearch] = useState(false)
  const toShow = 7
  const [amountToShow, setAmountToShow] = useState(toShow)

  const showMore = () => {
    setAmountToShow(prevAmountToShow => {
      const newAmount = prevAmountToShow + toShow;
      return newAmount;
    });
  };

  const showLess = () => {
    setAmountToShow(toShow);
  };

  useEffect(() => {
    if (listOfCups && listOfCups.length > 0 && !onSearch) {
      const reducedData = {
        mainData: listOfCups.slice(0, amountToShow),
      };
      setFilteredData(reducedData.mainData);
    }
  }, [amountToShow, listOfCups, onSearch]);

  const normalizeString = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  };

  const handleSearch = (event) => {
    const searchText = normalizeString(event.target.value);
  
    if (searchText !== '') {
      const searchWords = searchText.split(' ');
  
      const newData = listOfCups.filter(item => {
        return searchWords.every(word => 
          //normalizeString(item.country).includes(word) ||
          normalizeString(item.location).includes(word) ||
          normalizeString(item.date_from).includes(word) ||
          normalizeString(item.date_to).includes(word) ||
          normalizeString(item.month_from).includes(word) ||
          normalizeString(item.month_to).includes(word) ||
          normalizeString(item.nazov).includes(word)
        );
      });
  
      setFilteredData(newData);
      console.log('set search data', newData);
      setOnSearch(true);
    } else {
      setOnSearch(false);
    }
  };


  useEffect(() => {

    fetch('/api/statusOfCups', {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token.token
        },
      })
      .then(res => res.json())
      .then(({cups}) => {
      setListOfCups(cups);
      console.log('tu sme ',cups)
     
    });
  }, []);

  const changeStatus = async (cupId, required) => {

    console.log('cupId', cupId)
    try {
      const response = await fetch('/api/changeStatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token.token
        },
        body: JSON.stringify({ cupId, required }) // Include required data in the request body
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const { status } = await response.json();
  
      if (status === 'ok') {
          setListOfCups(prevListOfCups => {
            return prevListOfCups.map(cup => {
              if (cup.id === cupId) {
                return { ...cup, status: required }; // Update the status of the matched cup
              }
              return cup;
            });
          });
          setFilteredData(prevFilteredData => {
            return prevFilteredData.map(cup => {
              if (cup.id === cupId) {
                return { ...cup, status: required }; // Update the status of the matched cup
              }
              return cup;
            });
          });
        }
      else {
        console.log('no sucess')
      }


  
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateRelation = async (cupId, required) => {
    console.log('updateRelation id ', cupId, 'required ', required)

      try {
        const response = await fetch('/api/changeRelations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token.token
          },
          body: JSON.stringify({ cupId, required }) // Include required data in the request body
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const { status } = await response.json();
    
        if (status === 'ok') {
            setListOfCups(prevListOfCups => {
              return prevListOfCups.map(cup => {
                if (cup.id === cupId) {
                  return { ...cup, relations: required }; // Update the status of the matched cup
                }
                return cup;
              });
            });
            setFilteredData(prevFilteredData => {
              return prevFilteredData.map(cup => {
                if (cup.id === cupId) {
                  return { ...cup, relations: required }; // Update the status of the matched cup
                }
                return cup;
              });
            });
          }
        else {
          console.log('no sucess')
        }


    
      } catch (error) {
        console.error('Error:', error);
      }
    };



  const theme = useTheme()

  if (!listOfCups) {
    return 'loading'
  }

  return (
    <div style={{marginBottom:'30px', width: '99%', margin:'0 auto', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
      <TextField 
            sx={{marginBottom: '1em', marginTop: '1em',width: "87vw", maxWidth: `calc(${maxWidthSetting} - 10%)` }}  
            /*label="Hľadaj pretek"*/ 
            variant="outlined" 
            size="small"
            placeholder='meno, mesiac, lokalita, typ'
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            />
      { filteredData && (
        filteredData.map((item, index) => (
        <StyledBox key={item.id}>
          <Stack direction={'row'} sx={{maxWidth:'70%'}}>
            {/*<Typography mr={2}>{item.id }</Typography>*/}         
            <Typography sx={{ overflow: 'hidden', textAlign: 'left',textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: item.status === 0 ? 'lightgrey' : 'inherit',  }}>
              {item.nazov}
            </Typography>
          </Stack>
          <Stack direction='row'>
            {item.status === 0 ? (
              <StyledIconButton>
                <VisibilityOff onClick={()=>changeStatus(item.id, 1)} style={{color: 'lightgrey'}}/>
              </StyledIconButton>
            
            ) : (
            <StyledIconButton>
              <Visibility onClick={()=>changeStatus(item.id, 0)} />
            </StyledIconButton>

            )}
            { item.relations === 1? (
            <StyledIconButton>
              <LockOpen onClick={()=>updateRelation(item.id, 0)} />
            </StyledIconButton>            
            ):(
            <StyledIconButton>
              <Lock onClick={()=>updateRelation(item.id, 1)} style={{color: 'lightgrey'}}/>
            </StyledIconButton>
            )}
          </Stack>
        </StyledBox>
      )))}
      { !onSearch && (
          <Stack direction={'row'} pb={3} spacing={2} mb={5}>
            {listOfCups && amountToShow < listOfCups.length && (
              <Button sx={{minWidth:'100px', fontWeight: 'bold',textTransform: 'none'}} color='secondary' variant="text" onClick={showMore}>Viac</Button>
            )}  
            {listOfCups && (amountToShow <= listOfCups.length || amountToShow > toShow) && amountToShow != toShow  && (
              <Button sx={{minWidth:'100px', fontWeight: 'bold', textTransform: 'none'}} color='primary' variant="text"  onClick={showLess} >Menej</Button>
            )}
          </Stack>
          )}  
      <BottomNavigationMenu/>
    </div>
  )
}

export default StatusPretekov
