import { BottomNavigation, BottomNavigationAction, Box, ButtonBase, CircularProgress, Collapse, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Add,  Delete,  Edit, EditOff,  LinkOutlined,  } from '@mui/icons-material';
import { Link} from 'react-router-dom';
import settings from './Settings';

  // Retrieve token and access from localStorage
  const tokenJsonString = localStorage.getItem('token');
  // Parse JSON strings into objects
  const tokenObject = JSON.parse(tokenJsonString);
  // Extract token and access values
  const token = tokenObject ? tokenObject.access_token : null;
  
const TableCellStyled = styled(TableCell)(() => ({
    margin: '5px',
    padding: '5px',
    fontSize: '12px',
  }));


const deleteCup = (cupId, fetchData) =>  {

  // Display a confirmation dialog
  const confirmDelete = window.confirm("Chcete vymazať pretek??");
 
  // Check if the user confirmed
  if (confirmDelete) {
   
      fetch('/api/deleteCup', {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({'cupId':cupId})
      })
      .then(response => response.text())
      .then(data => {
        if (data === 'success') {
          fetchData()
          
        } else {
          console.log('Failed to delete catch');

        }
      })
      .catch(error => {
        console.error('Error deleting catch:', error);
        // Optionally, handle errors here
      });

    } else {
      console.log('Deletion canceled');
    }
  }


  function Row(props) {
    const theme = useTheme();
    const { index, row, isOpen, onRowClick, fetchData, editMode, setCupsData, openRowId } = props;
  
    // State variables for editable fields
    const [loading, setLoading] =  useState(false);
    const [name, setName] = useState(row.meno);
    const [location, setLocation] = useState(row.miesto);
    const [teams, setTeams] = useState(row.tími);
    const [rozhodca, setRozhodca] = useState(row.rozhodca);
    const [hesloRozhodcu, setHesloRozhodcu] = useState(row.hesloRozhodcu);
    const [order, setOrder] = useState({
      od: row.od,
      do: row.do,
      typ: row.typ,
      kapacita: row.kapacita,
      poplatok: row.poplatok,
      krajina: row.krajina
    });
  
    useEffect(() => {
      // Update state when row data changes
      setName(row.meno);
      setLocation(row.miesto);
      setTeams(row.tími);
      setRozhodca(row.rozhodca);
      setHesloRozhodcu(row.hesloRozhodcu);
      setOrder({
        od: row.od,
        do: row.do,
        typ: row.typ,
        kapacita: row.kapacita,
        poplatok: row.poplatok,
        krajina: row.krajina
      });
    }, [row]);
  
    const handleBlur = (id, property, newValue, teamId = 'notAplicable') => {
      if (newValue !== undefined && newValue !== null) {

        setLoading(true)

        if (property==='kapacita' && newValue < 1 ) {
          return;
        }

        fetch('/api/changeInCup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          },
          body: JSON.stringify({ cupId: id, updatedProperty: property, newValue, teamId })
        })
          .then(response => response.text())
          .then(data => {
            if (data === 'success') {
              console.log('Cup detail changed successfully');
              setCupsData(prevRows => {
                const updatedRows = prevRows.map(row => {
                  if (row.id === id) {
                    if (property === 'tími') {
                      const updatedTeams = row[property].map(team => {
                        if (team.id === teamId) {
                          return { ...team, name: newValue };
                        }
                        return team;
                      });
                      return { ...row, [property]: updatedTeams };
                    } else {
                      return { ...row, [property]: newValue };
                    }
                  }
                  return row;
                });
                return updatedRows;
              });
  
              if (property === 'kapacita') {
                fetchData();
              }

              setLoading(false)
            } else {
              console.log('Failed to change cup data');
              setLoading(false)
            }
          })
          .catch(error => {
            console.error('Error editing cup data:', error);
            setLoading(false)
          });
      } else {
        console.log('No change detected');
        setLoading(false)
      }
    };
  
  
    const orderKeys = ['od', 'do', 'typ', 'kapacita', 'poplatok', 'krajina'];
  
    return (
      <React.Fragment>
        <TableRow onClick={onRowClick} sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell align="center" component="th" scope="row" sx={{ width: '5%' }}>
            {index + 1}
          </TableCell>
          <TableCell
            contentEditable={editMode && row.id === openRowId}
            align="left"
            onBlur={(e) => handleBlur(row.id, 'meno', e.currentTarget.textContent)}
            sx={{
              width: '50%',
              direction: 'ltr',
              ...(editMode && row.id === openRowId && {
                backgroundColor: '#fae2e1',
                borderRadius: '10px',
                border: '1px solid white'
              })
            }}
          >
            {name}
          </TableCell>
          <TableCell
            contentEditable={editMode && row.id === openRowId}
            align="left"
            onBlur={(e) => handleBlur(row.id, 'miesto', e.currentTarget.textContent)}
            sx={{
              width: '50%',
              ...(editMode && row.id === openRowId && {
                backgroundColor: '#fae2e1',
                borderRadius: '10px',
                border: '1px solid white'
              })
            }}
          >
            {location}
          </TableCell>
          <TableCell align="center" sx={{ width: '20%' }}>
            { !editMode && (
            <Link to={`/Pretek/${row.id}`}>
              <LinkOutlined sx={{ color: theme.palette.secondary.main }} fontSize='small' />
            </Link>
            )}
          </TableCell>
          <TableCell align="left" sx={{ maxWidth: '10%' }}>{row.sum}</TableCell>
        </TableRow>
        <TableRow sx={{ margin: 1, background: '#F3F3F3' }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {orderKeys.map((key, index) => (
                      index % 2 === 0 && (
                        <TableRow key={index}>
                          <TableCell sx={{ maxWidth: '50px', fontWeight: 'bold' }} align="left">{orderKeys[index]}:</TableCell>
                          <TableCell
                            contentEditable={editMode && row.id === openRowId}
                            suppressContentEditableWarning={true}
                            onBlur={(e) => handleBlur(row.id, orderKeys[index], e.currentTarget.textContent)}
                            sx={{
                              maxWidth: '50px',
                              ...(editMode && row.id === openRowId && {
                                backgroundColor: '#fae2e1',
                                borderRadius: '10px',
                                border: '1px solid white'
                              })
                            }}
                            align="left"
                          >
                            {order[orderKeys[index]]}
                          </TableCell>
                          {orderKeys[index + 1] && (
                            <>
                              <TableCell sx={{ maxWidth: '50px', fontWeight: 'bold' }} align="left">{orderKeys[index + 1]}:</TableCell>
                              <TableCell
                                contentEditable={editMode && row.id === openRowId}
                                onBlur={(e) => handleBlur(row.id, orderKeys[index + 1], e.currentTarget.textContent)}
                                sx={{
                                  maxWidth: '50px',
                                  ...(editMode && row.id === openRowId && {
                                    backgroundColor: '#fae2e1',
                                    borderRadius: '10px',
                                    border: '1px solid white'
                                  })
                                }}
                                align="left"
                              >
                                {order[orderKeys[index + 1]]}
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      )
                    ))}
                  </TableBody>
                </Table>
  
                <Table size="small" aria-label="purchases" sx={{ marginTop: '20px' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" sx={{ width: '50%', fontWeight: 'bold' }}>Zoznam tímov</TableCell>
                    </TableRow>
                  </TableHead>
                  {teams.map((team, index) => (
                    index % 2 === 0 && (
                      <TableRow key={team.id}>
                        <TableCell
                          contentEditable={editMode && row.id === openRowId}
                          onBlur={(e) => handleBlur(row.id, 'tími', e.currentTarget.textContent, team.id)}
                          sx={{
                            paddingLeft: '20px',
                            ...(editMode && row.id === openRowId && {
                              backgroundColor: '#fae2e1',
                              borderRadius: '10px',
                              border: '1px solid white'
                            })
                          }}
                        >
                          {team.name}
                        </TableCell>
                        {teams[index + 1] && (
                          <TableCell
                            key={teams[index + 1].id}
                            contentEditable={editMode && row.id === openRowId}
                            onBlur={(e) => handleBlur(row.id, 'tími', e.currentTarget.textContent, teams[index + 1].id)}
                            sx={{
                              paddingLeft: '20px',
                              ...(editMode && row.id === openRowId && {
                                backgroundColor: '#fae2e1',
                                borderRadius: '10px',
                                border: '1px solid white'
                              })
                            }}
                          >
                            {teams[index + 1].name}
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  ))}
                </Table>
                <Table size="small" aria-label="purchases" sx={{ marginTop: '20px' }}>
                  <TableHead>
                      <TableCell align="left" sx={{ width: '80%', fontWeight: 'bold' }}>Rozhodcovský účet</TableCell>
                  </TableHead>
                </Table>    
                <Table size="small" aria-label="purchases" sx={{ marginTop: '20px' }}>
                  <TableBody>
                    <TableRow key="rozhodca">
                      <TableCell sx={{ fontWeight: "bold", paddingLeft: '20px' }} align="left">Meno</TableCell>
                      <TableCell
                        contentEditable={editMode && row.id === openRowId}
                        onBlur={(e) => handleBlur(row.id, 'rozhodca', e.currentTarget.textContent)}
                        sx={{
                          ...(editMode && row.id === openRowId && {
                            backgroundColor: '#fae2e1',
                            borderRadius: '10px',
                            border: '1px solid white'
                          }),
                          width: '70%'
                        }}
                        align="left"
                      
                      >
                        {rozhodca}
                      </TableCell>
                    </TableRow>
                    <TableRow key="hesloRozhodcu">
                      <TableCell sx={{ paddingLeft: '20px', fontWeight: "bold" }} align="left">Heslo</TableCell>
                      <TableCell
                        contentEditable={editMode && row.id === openRowId}
                        onBlur={(e) => handleBlur(row.id, 'hesloRozhodcu', e.currentTarget.textContent)}
                        sx={{
                          ...(editMode && row.id === openRowId && {
                            backgroundColor: '#fae2e1',
                            borderRadius: '10px',
                            border: '1px solid white'
                          }),
                          width: '70%'
                        }}
                        align="left"
                      >
                        {hesloRozhodcu}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
  
              </Box>
              {editMode && row.id === openRowId && (
                <Stack direction={'row'} justifyContent={'center'}>
                  <ButtonBase onClick={()=>deleteCup(row.id, fetchData)}>
                    <Delete  sx={{ padding: '10px', color: theme.palette.secondary.main }} />
                  </ButtonBase>
                </Stack>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
        { loading &&
            ( <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 9999, // Ensures it appears on top of other elements
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'grey',
                opacity: 0.5,
              }}
            >
              <CircularProgress color="secondary" />
          </Box>)
          }
      </React.Fragment>
    );
  }

const MyCups= () => {

  const theme = useTheme();

  
  const { maxWidthSetting, padding } = settings;
  const [openRowId, setOpenRowId] = useState(null);
  const [cupsData, setCupsData] = useState(null);
  const [editMode, setEditMode] =  useState(false)
  const [archivedData, setArchiveData] = useState('')

  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page with smooth behavior
  }, []); 


  const fetchData = () => {
    fetch('/api/myCupsData', {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : token
      },
    })
    .then(response => response.json())
    .then(({ data, archiveData }) => {
      setCupsData(data);
      setArchiveData(archiveData);
      console.log('Fetched data:', data);
      console.log('Fetched archived data:', archiveData);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };


  useEffect(() => {

    fetchData();

      
  }, []);




  const handleRowClick = (rowId) => {
    if (!editMode) {
      setOpenRowId(openRowId === rowId ? null : rowId);
    } else {
      return;
    }
  };


    if (cupsData === null) {
      return <div>Loading...</div>; // You can replace this with a loading spinner or any other loading indicator
    }


  return (
<Box style={ token ? {overflowX: 'auto', paddingBottom: '60px'} : { overflowX: 'auto', } }>        
<TableContainer component={Paper} sx={{ maxWidth: maxWidthSetting, margin: '0 auto' }}>
        <Table aria-label="collapsible table" >
            <TableHead>
            <TableRow >
                <TableCellStyled align="center">#</TableCellStyled>
                <TableCellStyled align="left">Názov</TableCellStyled>
                <TableCellStyled align="left">Lokalita</TableCellStyled>
                {!editMode &&(
                <TableCellStyled align="left">Tabulka</TableCellStyled>
                )}
            </TableRow>
            </TableHead>
            <TableBody>
            {cupsData.map((cup, index) => (
                <Row 
                key={cup.meno}
                cupsData={cupsData} 
                row={cup}
                openRowId={openRowId}
                index={index}
                isOpen={openRowId === cup.id}
                onRowClick={() => handleRowClick(cup.id)}
                fetchData={fetchData}
                editMode={editMode}
                setCupsData={setCupsData}
                cupId= {cup.id}
               />
            ))}
          
            </TableBody>
        </Table>
        {cupsData.length === 0 &&
            <Box sx={{width:'100vw'}}> 
            <Typography sx={{textAlign:'center', margin: '15px 0', fontSize:'0.8em'}}>nemáte žiadne aktívne súťaže</Typography>
            </Box>
            }
        </TableContainer>
        <Typography mt={3} mb={2} color={theme.palette.secondary.main}>Archívované:</Typography>
        {archivedData.map((archived, index) => (
          <>
          <Stack direction={'row'} justifyContent={'space-between'} sx={{maxWidth: maxWidthSetting, margin:'0 auto'}}>
            <Typography textAlign={'left'} ml={3} fontSize={15} key={index}>{archived.meno}</Typography>
            <Link to={`/Pretek/${archived.id}`}>
              <LinkOutlined sx={{ color: theme.palette.secondary.main, marginRight:'1.5em' }} fontSize='small' />
            </Link>
            </Stack>
          </>
        ))}
        { token && ( 
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
           <BottomNavigation
              sx={{possition: 'fixed', bottom: '0'}}
              showLabels
            > 
              <BottomNavigationAction label="Pridaj Pretek" icon={<Link to='/PridajPretek'><Add sx={{color: theme.palette.secondary.main}} /></Link>} />
              
              { (token && openRowId && editMode === false) &&  (
              <BottomNavigationAction onClick={()=> setEditMode(true)} label="Edit Mode On" icon={<Edit />} />
              )}
              { (token && openRowId && editMode === true) &&  (
              <BottomNavigationAction   onClick={()=> setEditMode(false)} label="Edit Mode Off" icon={<EditOff  fill='none' />} />
              )}
            </BottomNavigation>
          </Paper>
          )}
    </Box>
    

  )
}

export default MyCups
