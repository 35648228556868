import { Stack, Typography } from '@mui/material'
import React from 'react'

  // Retrieve token and access from localStorage
  const tokenJsonString = localStorage.getItem('token');
  const accessJsonString = localStorage.getItem('access');
  
  // Parse JSON strings into objects
  const tokenObject = JSON.parse(tokenJsonString);
  const accessObject = JSON.parse(accessJsonString);
  
  // Extract token and access values
  const token = tokenObject ? tokenObject.access_token : null;
 

const NoAccess = () => {
  return (
    <Stack justifyContent='center' height='70vh'>
      <Typography>
          Nemáte prístup k týmto stránkam.
      </Typography>
      {!token &&(
      <Typography>
        Prihláste sa.
      </Typography>
      )} 
    </Stack>
  )
}

export default NoAccess
