import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import settings from './Settings';
import { useTheme } from '@emotion/react';


const SignIn = () => {

const token = settings.getToken();
const storedUsername = localStorage.getItem('username');
const username = storedUsername ? JSON.parse(storedUsername).username : '';

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    console.log({
      userName: formData.get('userName'),
      password: formData.get('password'),
    });

    fetch('/api/login', {
        method: 'POST',
        body: formData
    })
    .then(response => response.json())
    .then(data => {

        console.log('data received by server', data)

        if (data.access_token) {

            const tokenExpirationTime = settings.tokenExpirationTime;
            const expirationTime = Date.now() + tokenExpirationTime;
            
            const tokenObject = { access_token: data.access_token };
            const tokenJsonString = JSON.stringify(tokenObject);
            localStorage.setItem('token', tokenJsonString);
            localStorage.setItem('tokenExpiration', expirationTime.toString());
            
            const accessObject = { access: data.access };
            const accessJsonString = JSON.stringify(accessObject);
            localStorage.setItem('access', accessJsonString);

            const usernameObject = { username: data.username };
            const usernameJsonString = JSON.stringify(usernameObject);
            localStorage.setItem('username', usernameJsonString);

            const userIdObject = { userId: data.userId };
            const userIdJsonString = JSON.stringify(userIdObject);
            localStorage.setItem('userId', userIdJsonString);

            console.log(accessJsonString, usernameJsonString, userIdJsonString)

            window.location.href = '/';
            
          }

          else if (data.response === 'Invalid password') {
            console.log('Invalid password')

          }

          else if (data.response === 'Invalid username') {
            console.log('Invalid username')

          }

          else if (data.response === 'Not approved yet') {
            console.log('Not approved yet')

          }

    })
    .catch(error => {
        console.log('error', error)
    });

    console.log('Form submitted!');

  };

  const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('access');
    localStorage.removeItem('username');
    localStorage.removeItem('userId');

    window.location.href = '/';

  };

  const theme = useTheme();


  return (
        <Box
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: 'calc(100vh - 140px)'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: theme.palette.secondary.main }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography sx={{fontWeight: '600', color: theme.palette.secondary.main}}>{username}</Typography>
          { !token && (
          <Typography component="h1" variant="h5">
            Prihlásiť sa
          </Typography>
        )}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          { !token && (
            <Box>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Meno"
              name="userName"
              autoComplete="name"
              autoFocus
            />
            <TextField
            margin="normal"
              required
              fullWidth
              name="password"
              label="Heslo"
              type="password"
              id="password"
              autoComplete="current-password"
              />
      
            <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            >
              Prihlásiť
            </Button>
            </Box>
            )}
            { token && (
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={()=>logOut()}
                >
                Odhlásiť
                </Button>
                )}
            {/*<Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
    */}
          </Box>
        </Box>

  );
}


export default SignIn
