import React from 'react';
import * as Icons from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

const NoteDisplay = ({ note }) => {

    const theme = useTheme()
    const renderNoteWithIcons = (note) => {
      // Split the note into parts and map them to components or text
      const parts = note.split(/(:[a-zA-Z0-9]+:|\s+)/).filter(Boolean);
      return parts.map((part, index) => {
        if (part.match(/:[a-zA-Z0-9]+:/)) {
          const iconName = part.slice(1, -1);
          const IconComponent = Icons[iconName];
          if (IconComponent) {
            return (
              <Box key={index} sx={{ margin: '0 3px', display: 'flex', alignItems: 'center', color: theme.palette.secondary.main }}>
                <IconComponent style={{ fontSize: 12, }} />
              </Box>
            );
          } else {
            return <span key={index} style={{  fontSize: '12px', color: theme.palette.secondary.main }}>{part}</span>;
          }
        }
        if (part.match(/\s+/)) {
          return <span key={index} style={{ whiteSpace: 'pre' }}>{part}</span>;
        }
        return <span key={index} style={{ fontSize: '12px', color: theme.palette.secondary.main }}>{part}</span>;
      });
    };
    
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left', alignItems:'center',margin: '5px 10px' }}>
        {renderNoteWithIcons(note)}
      </div>
    );
};

export default NoteDisplay;