import { useTheme } from '@emotion/react';
import { LockReset, Person } from '@mui/icons-material';
import { Box, ButtonBase, Stack, Typography, styled } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';

const StyledBox = styled(Box)(({ theme }) => ({
    margin: '20px',
    padding: '8px',
    fontSize: '12px',
    border: `1px solid ${theme.palette.primary.main}`,
    width: '80px',
    height: '80px',
    borderRadius: '10px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', // Adding some shadow
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px'

  }));

  const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration:'none', 
    color: `${theme.palette.primary.main}`,
  }));

const AdminMenu = () => {

    const theme = useTheme()

    return (
    <Box sx={{maxWidth:'600px', margin: '0 auto'}}>
        <Stack direction='row' alignItems='flex-start' justifyContent='center' flexWrap="wrap" width='100%'>
            <ButtonBase>
                <StyledLink to='/AddUser' >
                    <StyledBox>
                        <Person sx={{fontSize:"2.5em"}}/>
                        <Typography fontSize='1em'>
                            Pridaj Organizátora
                        </Typography>
                    </StyledBox>
                </StyledLink>
            </ButtonBase>    
            <ButtonBase>
                <StyledLink to='/StatusPretekov'>
                    <StyledBox>
                        <LockReset sx={{fontSize:"2.5em"}}/>
                        <Typography fontSize='1em'>
                            Status Pretekov
                        </Typography>
                    </StyledBox>
                </StyledLink>
            </ButtonBase>    
        </Stack>

    </Box>
  )
}

export default AdminMenu
