import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './content/Navbar';
import Header from './content/Header';
import Preteky from './content/Preteky';
import Footer from './content/Footer';
import Pretek from './content/Pretek';
import SignIn from './content/SignIn';
import {  useEffect, useState } from 'react';
import AddCupForm from './content/AddCupForm';
import MyCups from './content/MyCups';
import NoAccess from './content/NoAccess';
import AddUser from './content/AddUser';
import { messaging } from './firebase'; // Adjust the path based on your file structure
import { getToken, onMessage } from 'firebase/messaging';
import AdminMenu from './content/AdminMenu';
import StatusPretekov from './content/StatusPretekov';

  // Retrieve token and access from localStorage
  const tokenJsonString = localStorage.getItem('token');
  const accessJsonString = localStorage.getItem('access');
  
  // Parse JSON strings into objects
  const tokenObject = JSON.parse(tokenJsonString);
  const accessObject = JSON.parse(accessJsonString);
  
  // Extract token and access values
  const token = tokenObject ? tokenObject.access_token : null;
  const access = accessObject ? accessObject.access : null;

function App() {

  const getUserIdFromLocalStorage = () => {
    const userIdJsonString = localStorage.getItem('userId');
    const userIdObject = JSON.parse(userIdJsonString);
    return userIdObject ? userIdObject.userId : null;
  };

  const [notificationList ,setNotificationList] = useState([])
  const [unseenCount, setUnseenCount] = useState(0)
  const [userId, setUserId] = useState(getUserIdFromLocalStorage);

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        console.log('Page is now visible, refreshing...');
        // Incrementing refreshKey will trigger useEffect to refresh the page
        setRefreshKey(prevKey => prevKey + 1);
      }
    };
  

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

 

  const fetchNotifications = () => {
    fetch('/api/notifications', {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'userId' : userId
      },
    })
      .then(res => res.json())
      .then(data => {
      setNotificationList(data);
      const unseen = data.filter(notification => notification.seen === 0).length;
      setUnseenCount(unseen)
      console.log('unseen ', userId, data)
    
    });

  }

  useEffect(() => {

  fetchNotifications();

  }, [refreshKey]);

  //firebase popup notifications:

  useEffect(() => {

    if (!userId) return;

    // Request permission to send notifications
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          const fireBaseToken = await getToken(messaging, { vapidKey: 'BEfUt9ZT-A_WnDPzodysvsTqUi7rSSr3LrIU1KjOXMPob5l9S9VYGTf8M-AvyJqD9x7hLtpT59xeUl4LA3tfPCo' });
          if (fireBaseToken) {
            console.log('Current token:', fireBaseToken);
            // Send the token to your server and update the UI if necessary
            await saveTokenToServer(fireBaseToken);
            localStorage.setItem(`notificationPermission_${userId}`, 'granted');

          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        } else {
          console.log('Unable to get permission to notify.');
        }
      } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
      }
    };


    const shouldRequestPermission = () => {
      if (userId) {
        const permissionStatus = localStorage.getItem(`notificationPermission_${userId}`);
        return permissionStatus !== 'granted';
      }
      return false;
    };
    
    if (shouldRequestPermission()) {
      requestPermission();
    }

    // Handle incoming messages
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // Customize notification here
      const { title, body } = payload.notification;

      if (Notification.permission === 'granted') {
        new Notification(title, body);
      }
    });

  }, [userId]);

  // index.js or App.js
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registration successful with scope: ', registration.scope);
      }).catch((err) => {
        console.log('Service Worker registration failed: ', err);
      });
  }

  const handleUserIdUpdate = () => {
    
    setUserId(getUserIdFromLocalStorage);
  };

  const saveTokenToServer = async (fireBaseToken) => {
    try {
      const response = await fetch('/api/save-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fireBaseToken, userId }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to save token to server');
      }
    } catch (error) {
      console.error('Error saving token to server:', error);
    }
  };


  return (
      <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh',  margin: '0 auto' }} >
    <Router>
        <Navbar fetchNotifications={fetchNotifications} setNotificationList={setNotificationList} setUnseenCount={setUnseenCount} notificationList={notificationList} unseenCount={unseenCount}/>
        <Routes>
        <Route path="/" element={<><Header/><Preteky fetchNotifications={fetchNotifications} handleUserIdUpdate={handleUserIdUpdate}/><Footer /> </>} />
          <Route path="/Pretek/:cupId" element={<Pretek fetchNotifications={fetchNotifications}/>} />
          <Route path="/SignIn" element={<SignIn />} />
          {token && access === 9 &&(
            <>
            <Route path="/AddUser" element={<AddUser />} />
            <Route path="/AdminMenu" element={<AdminMenu />} />
            <Route path="/StatusPretekov" element={<StatusPretekov token={token}/>} />

            </>
          )}
          {token && access > 0 ? (
            <>
              <Route path="/PridajPretek" element={<AddCupForm />} />
              <Route path="/MojePreteky" element={<MyCups />} />
            </>
          ) : 
          <>
            <Route path="/PridajPretek" element={<NoAccess />} />
            <Route path="/MojePreteky" element={<NoAccess />} />
          </>
          }
        </Routes> 
    </Router>
      </div>
  );
}

export default App;