import {  AddCircleOutlineOutlined, KeyboardBackspace } from '@mui/icons-material';
import { Box,  ButtonBase, CircularProgress, Stack, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import settings from './Settings';

const {maxWidthSetting} = settings

const AddFishForm = ({handleClose, openSnackFunction}) => {

  const tokenData = JSON.parse(localStorage.getItem('token') || '{}');
  const token = tokenData.access_token || '';

    const AdIconBox = styled(Box)(({ selected, openAlert, selectedCategory }) => ({
        width: '45px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        padding: '5px 5px',
        flexDirection: 'column',
        textAlign: 'center',
        borderRadius: '5px',
        fontWeight: 700,
        color: openAlert && !selectedCategory ? 'red' : 'white',
        backgroundColor: selected ? '#c4e1ff' : 'rgb(103, 58, 183)',
        '&:hover' :{
            cursor: 'pointer'
          },
    }));

    const NumberBox = styled(Box)(() => ({
        width: '70px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        padding: '10px 5px',
        flexDirection: 'column',
        fontWeight: 700,
        textAlign: 'center',
        borderRadius: '5px',
        margin: '2px',
        color: 'white',
        backgroundColor: 'rgb(103, 58, 183)',
        transition: 'transform 0.5s ease', // Add transition for the transform property
        cursor: 'pointer', // Add cursor pointer to indicate clickability
      }));

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [weightValue, setWeightValue] = useState('')
    const { cupId } = useParams()
    const [addFormData, setAddFormData] = useState(null)
    
    const [addingFish, setAddingFish] = useState(null)

    const handleClick = (category) => {
        setSelectedCategory(category === selectedCategory ? null : category);
    };

    const handleNumberBoxClick = (value) => {
        if (value === 'backspace') {
          setWeightValue((prevValue) => prevValue.slice(0, -1)); // Remove the last character
        } else if (value === '.') {
          if (!weightValue.includes('.')) {
            setWeightValue((prevValue) => prevValue + '.');
          }
        } else {
          setWeightValue((prevValue) => `${prevValue}${value}`); // Append the clicked value
        }
      };

    useEffect(() => {

      fetch('/api/addFishFormData', {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'cupId' : cupId})
      })
      .then(response => response.json())
      .then(data => {
        setAddFormData(data);
        console.log(data)
        });
    
      }, [cupId]);

    const handleSubmit = () => {

        // Create a FormData object to handle file uploads
        const formData = new FormData();
        formData.append('teamId', addFormData[selectedCategory - 1].id);
        formData.append('name',  addFormData[selectedCategory - 1].name);
        formData.append('sector', addFormData[selectedCategory - 1].sector);
        formData.append('weight', weightValue);
        formData.append('cupId', cupId);
        setAddingFish(true)

      
        fetch('/api/uploadWeight', {
            method: 'POST',
            headers: {
              'Authorization': token
            },
            body: formData
        })
        .then(response => {
          if (!response.ok) {

              handleClose()
              openSnackFunction('Chyba. Skúste sa znovu prihlásiť.', 'error')
              throw new Error('Network response was not ok');  
            }
          
          return response.json();
      })
        .then(data => {
            //const fromServer = data.response;
            setAddingFish(false)
            handleClose()

            
            openSnackFunction('Ryba bola úspešne pridaná!', 'success')
            
                      
            
        })
        .catch(error => {
            console.log('error', error)
        });

        console.log('Form submitteda!');
        
      };   

  if (addFormData === null) {
    return <div>Loading...</div>; // You can replace this with a loading spinner or any other loading indicator
  }

  return (
    <Box sx={{background:'white', height:'100vh', width:'100vw', textAlign: 'center', position: 'relative'}}>
      <Box sx={{ overflowY: 'auto', height: 'calc(100% - 50px)', maxWidth: maxWidthSetting, margin: '0 auto'}}>
        <Stack direction='row' justifyContent='flex-end'  >
            <ButtonBase onClick={handleClose}>
                <Typography sx={{margin:'10px'}} >X</Typography>
            </ButtonBase>
        </Stack>
        <Typography color={'primary'} variant='h5' sx={{paddingBottom:'10px', fontWeight:700}}>Vyber lovné miesto</Typography>
        <Stack direction='row' flexWrap='wrap' justifyContent='center'>
        {addFormData.map((data, index) => (
          <AdIconBox
            selectedCategory={selectedCategory}
            selected={selectedCategory === index + 1}
            onClick={() => handleClick(index + 1)}
            key={index}
            color='secondary'
            variant="contained"
            sx={{ margin: '2px' }}
          >
            {data.sector}
          </AdIconBox>
        ))}
        </Stack>
        <Typography>
          Tím: {selectedCategory !== null && addFormData[selectedCategory - 1] ? addFormData[selectedCategory - 1].name : ''}
        </Typography>
        <Typography color={'primary'} variant='h5' sx={{padding:'10px', fontWeight:700}}>Zadaj Váhu</Typography>
        <Box sx={{width:'250px', height:'30px', borderRadius:'5px', border:'1px solid', margin: '0px auto'}}>
            <Typography variant='h5' sx={{marginTop:'0px'}}>{weightValue}</Typography>
        </Box>
        <Stack direction='column' flexWrap='wrap' alignItems='center' padding={3} paddingBottom={5}>
            <Stack direction='row'>
                <NumberBox onClick={() => handleNumberBoxClick(1)}>1</NumberBox>
                <NumberBox onClick={() => handleNumberBoxClick(2)}>2</NumberBox>
                <NumberBox onClick={() => handleNumberBoxClick(3)}>3</NumberBox>
            </Stack>
            <Stack direction='row'>
                <NumberBox onClick={() => handleNumberBoxClick(4)}>4</NumberBox>
                <NumberBox onClick={() => handleNumberBoxClick(5)}>5</NumberBox>
                <NumberBox onClick={() => handleNumberBoxClick(6)}>6</NumberBox>
            </Stack>
            <Stack direction='row'>
                <NumberBox onClick={() => handleNumberBoxClick(7)}>7</NumberBox>
                <NumberBox onClick={() => handleNumberBoxClick(8)}>8</NumberBox>
                <NumberBox onClick={() => handleNumberBoxClick(9)}>9</NumberBox>
            </Stack>
            <Stack direction='row'>
                <NumberBox onClick={() => handleNumberBoxClick('.')}>,</NumberBox>
                <NumberBox onClick={() => handleNumberBoxClick(0)}>0</NumberBox>
                <NumberBox onClick={() => handleNumberBoxClick('backspace')}><KeyboardBackspace sx={{fontSize:'15px'}}/></NumberBox>

            </Stack>
        </Stack>
      </Box>
      {
        selectedCategory !== null && weightValue > 0 &&  !addingFish && (
          <ButtonBase sx={{                
            position: 'fixed',
            bottom: 0,  
            width: '100%',  
            display: 'flex',
            justifyContent: 'center',}}
            onClick={handleSubmit}
            >
            <Box
              sx={{
                width: { xs: '100vw', sm: '100vw' },
                height: '80px',
                backgroundColor: (theme) => theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 'auto',

              }}
            >
              <AddCircleOutlineOutlined fill='none' sx={{ fontSize: '40px', color: 'white' }} />
            </Box>
          </ButtonBase>
        )
      }
      { addingFish &&
        (<CircularProgress />)
      }


    </Box>
  )
}

export default AddFishForm
