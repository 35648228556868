import { Alert, BottomNavigation, BottomNavigationAction, Box, FormControl, FormHelperText, Input, InputLabel, Paper, Snackbar, Stack,  styled } from '@mui/material'
import React, { useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Add } from '@mui/icons-material';


const StyledFormControl = styled(FormControl) (() => ({

    margin: '10px 0'

}));

const AddCupForm = () => {

    const tokenData = JSON.parse(localStorage.getItem('token') || '{}');
    const token = tokenData.access_token || '';

    const [openSnack, setOpenSnack] = useState(false);
    const [disabled, setDisabled] = useState(false);


    const handleCloseSnack = () => {
        setOpenSnack(false)
    }

    const initialFormValues = {
        name: '',
        place: '',
        type: '',
        country: '',
        capacity: '',
        fee: '',
        dateFrom: null,
        dateTo: null,
        timeFrom: null,
        timeTo: null,
        number: '',
    };

    const [formValues, setFormValues] = useState(initialFormValues);
    
      const [formErrors, setFormErrors] = useState({
        name: false,
        place: false,
        type: false,
        country: false,
        capacity: false,
        fee: false,
        dateFrom: false,
        dateTo: false,
        timeFrom: false,
        timeTo: false,
        number: false,
      });

      const isNumeric = (value) => {
        return !isNaN(value) && value.trim() !== '';
    }

      
    const submit = async (id) => {
        let errors = {};
    
        errors.capacity = !isNumeric(formValues.capacity);
        errors.fee = !isNumeric(formValues.fee);
        errors.number = !isNumeric(formValues.number);

        // Add checks for other fields as needed
        errors.name = formValues.name.trim() === '';
        errors.place = formValues.place.trim() === '';
        errors.type = formValues.type.trim() === '';
        errors.country = formValues.country.trim() === '';
        errors.dateFrom = !formValues.dateFrom;
        errors.dateTo = !formValues.dateTo;
        errors.timeFrom = !formValues.timeFrom;
        errors.timeTo = !formValues.timeTo;
    
        setFormErrors(errors);
    
        const hasErrors = Object.values(errors).some(error => error);
    
        if (!hasErrors) {
           
            try {
                setDisabled(true);
                const response = await fetch('/api/newCup', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    },
                    body: JSON.stringify(formValues)
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const result = await response.json();
                
                setFormValues(initialFormValues)
                setOpenSnack(true)
                setDisabled(false)

            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }

            console.log(id);
        } else {
            // Handle form validation errors
            console.log('Form has errors:', errors);
        }
    }

    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [id]: value,
        }));
        console.log({ ...formValues, [id]: value });
      };

    const handleDateChange = (id, date) => {
        setFormValues({
            ...formValues,
            [id]: date,
            });
        }
        console.log({ ...formValues});
  return (
        <Box sx={{background:'white', height: '100%', width:'100vw', textAlign: 'center', position: 'relative', overflowY: 'auto'}}>
            <Stack flexDirection='column'  alignItems='center' margin='auto' sx={{ width: '100%', maxWidth: '90vw', paddingBottom: '60px' }}>
                <StyledFormControl  error={formErrors.name} variant="standard">
                    <InputLabel>Názov súťaže</InputLabel>
                    <Input
                    value={formValues['name']} 
                    sx={{maxWidth:'1000px', width:'90vw'}}
                    id='name' 
                    onChange={handleChange}
                    placeholder="Zadajte názov pretekov"
                    aria-describedby="component-error-text"
                    />
                    <FormHelperText>{formErrors.name? 'chýba' : ''}</FormHelperText>
                </StyledFormControl>
                <Stack flexDirection='row' justifyContent="space-between" sx={{ width: '100%', maxWidth: '1000px' }}>
                    <StyledFormControl error={formErrors.place} variant="standard">
                        <InputLabel>Miesto súťaže</InputLabel>
                        <Input
                        value={formValues['place']}
                        sx={{maxWidth:'400px', width:'40vw'}}
                        id="place"
                        onChange={handleChange}
                        placeholder="Zadajte názov miesta súťaže"
                        aria-describedby="component-error-text"
                        />
                        <FormHelperText>{formErrors.place? 'chýba' : ''}</FormHelperText>
                    </StyledFormControl>
                    <StyledFormControl error={formErrors.number} variant="standard">
                        <InputLabel>Počet Bodovaných Rýb</InputLabel>
                        <Input
                        value={formValues['number']}
                        sx={{ maxWidth:'400px', width:'40vw'}}
                        id="number"
                        onChange={handleChange}
                        placeholder="1-9999"
                        aria-describedby="component-error-text"
                        />
                        <FormHelperText>{formErrors.number? 'Nesmie byť prázdy a musí byť číslo.' : ''}</FormHelperText>
                    </StyledFormControl>
                </Stack>
                <Stack flexDirection='row' justifyContent="space-between" sx={{ width: '100%', maxWidth: '1000px' }}>
                    <StyledFormControl error={formErrors.type} variant="standard">
                        <InputLabel>Tip súťaže</InputLabel>
                        <Input
                        value={formValues['type']}
                        sx={{ maxWidth:'400px', width:'40vw'}}
                        id="type"
                        onChange={handleChange}
                        placeholder="Zadajte Tip (Kapor...)"
                        aria-describedby="component-error-text"
                        />
                        <FormHelperText>{formErrors.type? 'chýba' : ''}</FormHelperText>
                    </StyledFormControl>                
                    <StyledFormControl error={formErrors.country} variant="standard">
                        <InputLabel>Krajina</InputLabel>
                        <Input
                        value={formValues['country']}
                        sx={{ maxWidth:'400px', width:'40vw'}}
                        id="country"
                        onChange={handleChange}
                        placeholder="Zadajte Krajinu"
                        aria-describedby="component-error-text"
                        />
                        <FormHelperText>{formErrors.country? 'chýba' : ''}</FormHelperText>
                    </StyledFormControl>
                </Stack>
                <Stack flexDirection='row' justifyContent="space-between" sx={{ width: '100%', maxWidth: '1000px' }}>
                    <StyledFormControl error={formErrors.capacity} variant="standard">
                        <InputLabel>Kapacita</InputLabel>
                        <Input
                        value={formValues['capacity']}
                        sx={{ maxWidth:'400px', width:'40vw'}}
                        id="capacity"
                        onChange={handleChange}
                        placeholder="Zadajte číslo."
                        aria-describedby="component-error-text"
                        />
                        <FormHelperText>{formErrors.capacity? 'Nesmie byť prázdy a musí byť číslo.' : ''}</FormHelperText>
                    </StyledFormControl>                
                    <StyledFormControl error={formErrors.fee} variant="standard">
                        <InputLabel>Poplatok</InputLabel>
                        <Input
                        value={formValues['fee']}
                        sx={{ maxWidth:'400px', width:'40vw'}}
                        id="fee"
                        onChange={handleChange}
                        placeholder="Zadajte hodnotu."
                        aria-describedby="component-error-text"
                        />
                        <FormHelperText>{formErrors.fee? 'Nesmie byť prázdy a musí byť číslo.' : ''}</FormHelperText>
                    </StyledFormControl>
                </Stack>
                <Stack flexDirection='row' justifyContent="space-between" sx={{ width: '100%', maxWidth: '1000px', padding: '0px 0' }}>
                    <StyledFormControl error={formErrors.dateFrom} variant="standard">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                        value={formValues['dateFrom']}
                        format="DD-MM-YYYY"
                        onChange={(date) => handleDateChange('dateFrom', date)}
                        label="Dátum začitku" 
                        sx={{ maxWidth:'400px', width:'45vw', border: formErrors.dateFrom ? '1px solid red' : 'none'}}/>
                        </LocalizationProvider>
                        <FormHelperText>{formErrors.dateFrom? 'chýba' : ''}</FormHelperText>
                    </StyledFormControl>                
                    <StyledFormControl error={formErrors.dateTo} variant="standard">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                        value={formValues['dateTo']}
                        onChange={(date) => handleDateChange('dateTo', date)}                        
                        format="DD-MM-YYYY" 
                        label="Dátum konca" 
                        sx={{ maxWidth:'400px', width:'45vw', border: formErrors.dateTo ? '1px solid red' : 'none'}}/>
                        </LocalizationProvider>
                        <FormHelperText>{formErrors.dateTo? 'chýba' : ''}</FormHelperText>
                    </StyledFormControl>                                   
                </Stack>
                <Stack flexDirection='row' justifyContent="space-between" sx={{ width: '100%', maxWidth: '1000px', marginTop: '10px' }}>
                    <FormControl error={formErrors.timeFrom} variant="standard">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker  
                        value={formValues['timeFrom']}
                        onChange={(time) => handleDateChange('timeFrom', time)}
                        label="Čas začiatku" 
                        ampm={false}
                        sx={{ maxWidth:'400px', width:'45vw', border: formErrors.timeFrom ? '1px solid red' : 'none'}}/>
                        </LocalizationProvider>
                        <FormHelperText>{formErrors.timeFrom? 'chýba' : ''}</FormHelperText>
                    </FormControl>                
                    <FormControl error={formErrors.timeTo} variant="standard">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker  
                        
                        value={formValues['timeTo']}
                        onChange={(time) => handleDateChange('timeTo', time)}
                        label="Čas konca"
                        ampm={false} 
                        sx={{
                            maxWidth: '400px',
                            width: '45vw',
                            border: formErrors.timeTo ? '1px solid red' : 'none'
                        }}/>
                        </LocalizationProvider>
                        <FormHelperText>{formErrors.timeTo? 'chýba' : ''}</FormHelperText>
                    </FormControl>                                   
                </Stack>
            </Stack>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                sx={{possition: 'fixed', bottom: '0'}}
                showLabels
                >   
                    <BottomNavigationAction disabled={disabled} onClick={submit} label="Pridaj Súťaž" icon={<Add/>} />
                </BottomNavigation>
          </Paper>
          <Snackbar
          open={openSnack}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleCloseSnack}
          autoHideDuration={2000}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              variant="filled"
              sx={{ width: '100%' }}
              autoHideDuration={2000}
            >
              Pretek bol úspešne pridaný.
            </Alert>
          </Snackbar>
        </Box>
  )
}

export default AddCupForm
