import { Alert, BottomNavigation, BottomNavigationAction, Box, ButtonBase, CircularProgress, Collapse, IconButton, Modal, Paper, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Add, Close, Delete, Edit, EditNote, EditOff, EmojiEventsTwoTone,  Looks3TwoTone, Looks4TwoTone, Looks5TwoTone, LooksOneTwoTone, LooksTwoTwoTone, Phishing, Print } from '@mui/icons-material';
import {  useParams } from 'react-router-dom';
import AddFishForm from './AddFishForm';
import { alpha } from '@mui/material/styles';
import settings from './Settings';
import html2canvas from 'html2canvas';
import NoteCreator from './NoteCreator';
import NoteDisplay from './NoteDisplay';

const {maxWidthSetting} = settings
  
    // Retrieve token and access from localStorage
    const tokenJsonString = settings.getToken();
    const accessJsonString = localStorage.getItem('access');
    
    // Parse JSON strings into objects
    const tokenObject = JSON.parse(tokenJsonString);
    const accessObject = JSON.parse(accessJsonString);
    
    // Extract token and access values
    const token = tokenObject ? tokenObject.access_token : null;
  const access = accessObject ? accessObject.access : null;

const TableCellStyled = styled(TableCell)(() => ({
    margin: '5px',
    padding: '5px',
    fontSize: '12px',
  }));

const TableCellStyledCrossedOut = styled(TableCell)(() => ({
    margin: '5px',
    padding: '5px',
    fontSize: '12px',
    textDecoration: 'line-through'

}));

const deleteFish = (catchId, weight, fetchData, cupId, openSnackFunction) =>  {

  // Display a confirmation dialog
  const confirmDelete = window.confirm("Chcete vymazať úlovok hmotnosti "+ weight + "kg ??");
 
  const tokenData = JSON.parse(localStorage.getItem('token') || '{}');
  const token = tokenData.access_token || '';

  // Check if the user confirmed
  if (confirmDelete) {
    // User confirmed, proceed with deletion
    console.log('Deleting fish with id:', catchId, cupId);

      fetch('/api/deleteFish', {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({'catchId': catchId, 'cupId':cupId})
      })
      .then(response => response.text())
      .then(data => {
        if (data === 'success') {
          fetchData()
          openSnackFunction('Ryba bola úspešne zmazaná!');

        } else {
          console.log('Failed to delete catch');

        }
      })
      .catch(error => {
        console.error('Error deleting catch:', error);
        // Optionally, handle errors here
      });

    } else {
      console.log('Deletion canceled');
    }
  }







function Row(props) {
  const { index, row, isOpen, onRowClick, fetchData, editMode, setCupData, cupId, openSnackFunction, owner, notCrossedOutAmount, hasSector2, changeTeamName } = props;

  //sem si ukladam nove hodnoty sectoru pri edite
  const sector1Ref = useRef(null);
  const sector2Ref = useRef(null);

  const [isEditingSector1, setIsEditingSector1] = useState(false);
  const [isEditingSector2, setIsEditingSector2] = useState(false);
  
   

  //pri editovani sectorov setujem isEditing aby nezobrazovalo predoslu hodnotu
  const handleFocus = (property) => {
    if (property === 'sector1') {
      setIsEditingSector1(true);
    } else if (property === 'sector2') {
      setIsEditingSector2(true);
    }
  };

  //zakazujem enter a space pri editovanu sektoru
  const handleKeyDown = (event) => {
    // Prevent default behavior for Enter and Space keys
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
    }
  };

  // funkcia ktora sa trigerne po tom ako ukoncim edit pola sectoru
  const handleBlur = (id, property, ref, cupId) =>  {
    const newValue = ref.current.innerText;
    console.log('handle blur ', id, property, newValue )
  
    if (newValue){

      fetch('/api/changeSector', {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({'teamId': id, 'updatedSector': property, 'newValue': newValue, 'cupId': cupId })
      })
      .then(response => response.text())
      .then(data => {
        if (data === 'success') {
          console.log('Sector changes succesfully');
          
          setCupData(prevRows => {
            return prevRows.map(row => {
              if (row.id === id) {
                return { ...row, [property]: newValue }; // Update the specific property
              }
              return row;
            });
          });

        } else {
          console.log('Failed to change sector');

        }
      })
      .catch(error => {
        console.error('Error setting sector:', error);
        // Optionally, handle errors here
      });
    } else {
      console.log('ziadna zmena')
    }

    // Reset editing state
    if (property === 'sector1') {
      setIsEditingSector1(false);
    } else if (property === 'sector2') {
      setIsEditingSector2(false);
    }
  };

  const deleteSector = (teamId, sector, cupId) =>  {
      
    console.log(teamId, sector, cupId)

    fetch('/api/deleteSector', {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({'teamId': teamId, 'updatedSector': sector, 'cupId': cupId, })
    })
    .then(response => response.text())
    .then(data => {
      if (data === 'success') {
        console.log('Sector changes succesfully');
        
        setCupData(prevRows => {
          return prevRows.map(row => {
            if (row.id === teamId) {
              return { ...row, [sector]: '' }; // Update the specific property
            }
            return row;
          });
        });

      } else {
        console.log('Failed to change sector');

      }
    })
    .catch(error => {
      console.error('Error setting sector:', error);
      // Optionally, handle errors here
    });
 
  }

    //Ikony pre ulovky a filter"
    const icons = [LooksOneTwoTone, LooksTwoTwoTone, Looks3TwoTone, Looks4TwoTone, Looks5TwoTone];
    const validCatches = row.catches.filter(c => c.weight > 0);

    // farba ikon ulovkou podla velkosti ryby
    const theme = useTheme();

    const backgroundColorWithOpacity = alpha(theme.palette.primary.main, 0.2);

    const getColorBasedOnWeight = (weight) => {
      if (weight >= 20) {
        return theme.palette.secondary.main; // Color for weight > 20kg
      } else if (weight >= 15) {
        return theme.palette.primary.main; // Color for weight > 15kg
      } else if (weight > 0) {
        return theme.palette.success.main; // Color for weight > 0kg
      } else {
        return 'grey'; // Default color
      }
    };


    return (
      <React.Fragment>
        <TableRow  onClick={onRowClick} sx={{ '& > *': { borderBottom: 'unset' }, height: '36px' }}>
          <TableCellStyled align="center" component="th" scope="row" sx={{width:'5%',}}>
          {index + 1}
          </TableCellStyled>
          <TableCellStyled 
            align="left"
            onBlur={(e) => changeTeamName(row.cupId, 'tími', e.currentTarget.textContent, row.id)} 
            sx={{width:'35%', ...(editMode && {
              backgroundColor: backgroundColorWithOpacity,
              borderRadius: '10px',
              border: '1px solid white'})}} 
            contentEditable={editMode}>
            {row.name}
          </TableCellStyled>
          <TableCellStyled 
            contentEditable={editMode} 
            ref={sector1Ref} 
            onFocus={() => handleFocus('sector1')} 
            onBlur={()=>handleBlur(row.id, 'sector1', sector1Ref, cupId)}
            onKeyDown={handleKeyDown}
            align="center" 
            suppressContentEditableWarning={true}
            sx={{width:'7.5%', ...(editMode && {
              backgroundColor: backgroundColorWithOpacity,
              borderRadius: '10px',
              border: '1px solid white'})
              }}>
                {isEditingSector1 ? null : row.sector1}
          </TableCellStyled>
          {editMode && (
          <TableCellStyled>
            <ButtonBase onClick={()=>deleteSector(row.id, 'sector1', row.cupId)} sx={{width:'7.5%', color: 'red', padding: '0 5px'}}>x</ButtonBase>
          </TableCellStyled>
          )}
          {(hasSector2 || editMode) && (
          <TableCellStyled 
            contentEditable={editMode}  
            ref={sector2Ref} 
            onFocus={() => handleFocus('sector2')} 
            onBlur={()=>handleBlur(row.id, 'sector2', sector2Ref, cupId)}
            onKeyDown={handleKeyDown}
            align="center" 
            suppressContentEditableWarning={true}
            sx={{width:'7.5%', ...(editMode && {
              backgroundColor: backgroundColorWithOpacity,
              borderRadius: '10px',
              border: '1px solid white'})
              }}>
              {isEditingSector2 ? null : row.sector2}
          </TableCellStyled>
          )}
          {editMode && (
          <TableCellStyled>
            <ButtonBase onClick={()=>deleteSector(row.id, 'sector2', cupId)} sx={{width:'7.5%', color: 'red', padding: '0 5px'}}>x</ButtonBase>
          </TableCellStyled>
          )}
          <TableCellStyled align="center" sx={{ width: '20%' }}>
            {notCrossedOutAmount >= 1 && notCrossedOutAmount <= 5 ? (
              icons.slice(0, notCrossedOutAmount).map((IconComponent, index) => {
                const color = validCatches[index] ? getColorBasedOnWeight(validCatches[index].weight) : 'grey';
                return (
                  <IconComponent
                    key={index}
                    style={{ color: color, width: '12px' }}
                  />
                );
              })
            ) : (
              <>{validCatches.length < notCrossedOutAmount ? validCatches.length : notCrossedOutAmount}</>
            )}
          </TableCellStyled>
          <TableCellStyled align="center" sx={{maxWidth:'10%'}}>{row.sum.toFixed(2)}</TableCellStyled>
        </TableRow>
        <TableRow sx={{ margin: 1, background: '#F3F3F3' }}>
          <TableCellStyled style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse  in={isOpen && !editMode} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1}}>
                <Typography variant="h7" gutterBottom component="div">
                  {row.name}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCellStyled align="center">Váha</TableCellStyled>
                      <TableCellStyled align="center">Lov.Mies.</TableCellStyled>
                      <TableCellStyled align="center">Dátum</TableCellStyled>
                      <TableCellStyled align="center">Čas</TableCellStyled>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {row.catches
                    .filter(catchesRow => catchesRow.weight !== 0) // Filter out rows where weight is 0
                    .map((catchesRow, index) => (
                      <TableRow key={catchesRow.catchId}>
                        {index<notCrossedOutAmount?(
                          <>
                          <TableCellStyled align="center">{catchesRow.weight}</TableCellStyled>
                          <TableCellStyled align="center">{catchesRow.id_sector}</TableCellStyled>
                          <TableCellStyled align="center">{catchesRow.date}</TableCellStyled>
                          <TableCellStyled align="center">{catchesRow.time}</TableCellStyled>
                          <TableCellStyled align="center">
                            <ButtonBase onClick={() => deleteFish(catchesRow.catchId, catchesRow.weight, fetchData, cupId, openSnackFunction)}>
                            {(token && owner) && (
                              <Typography color={'red'} xs={{padding:'0 10px'}}>
                                x
                              </Typography>
                            )}
                            </ButtonBase>
                          </TableCellStyled>
                          </>
                        ) : (
                        <>
                          <TableCellStyledCrossedOut align="center">{catchesRow.weight}</TableCellStyledCrossedOut>
                          <TableCellStyledCrossedOut align="center">{catchesRow.id_sector}</TableCellStyledCrossedOut>
                          <TableCellStyledCrossedOut align="center">{catchesRow.date}</TableCellStyledCrossedOut>
                          <TableCellStyledCrossedOut align="center">{catchesRow.time}</TableCellStyledCrossedOut>
                          <TableCellStyledCrossedOut align="center">
                            <ButtonBase onClick={() => deleteFish(catchesRow.catchId, catchesRow.weight, fetchData, cupId, openSnackFunction)}>
                            {(token && owner) && (
                              <Typography color={'red'} xs={{padding:'0 10px'}}>
                                x
                              </Typography>
                            )}
                            </ButtonBase>
                          </TableCellStyledCrossedOut>
                          </>
                        )}  
                      </TableRow>
                    ))
                  }
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCellStyled>
        </TableRow>
      </React.Fragment>
    );
  }


const Pretek = ({fetchNotifications}) => {

  
  const theme = useTheme();

  const [openRowId, setOpenRowId] = useState(null);
  const [cupData, setCupData] = useState(null);
  const { cupId } = useParams()
  const [open, setOpen] = useState(false)
  const [editMode, setEditMode] =  useState(false)
  const [openSnack, setOpenSnack] =  useState(false)
  const [snackMessage, setSnackMessage] = useState('');
  const [owner, setOwner] = useState(false);
  const [severity, setSeverity] = useState(''); 
  const [subscribed, setSubscribed]  = useState(false);
  const [printing, setPrinting] = useState(false)
  const printRef = useRef();
  const [openImage, setOpenImage] = useState(false);
  const [imageUrl ,setImageUrl] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cupName, setCupName] = useState('')
  const [numberOfScoredFish, setNumberOfScoredFish] = useState(null)
  const [notCrossedOutAmount, setNotCrossedOutAmount] = useState(9999)
  //const [notes, setNotes] = useState([{'name':'note 2', 'id':5, 'icon': 'Phishing'},{'name':'note 1', 'id':2, 'icon': 'Phishing'},{'name':'note 3', 'id':3, 'icon': 'Phishing'},{'name':'note 4', 'id':4, 'icon': 'Phishing'}])
  const [notes2, setNotes2] = useState([{'id': 1, 'note' : ':EmojiEmotions:'}, {'id': 1, 'note' : ':EmojiEmotions:'}, {'id': 1, 'note' :  ' tu som  :EmojiEmotions:  a aj tu som  :Favorite:'}])

  const changeTeamName = (id, property, newValue, teamId = 'notAplicable') => {
    if (newValue !== undefined && newValue !== null) {

      setLoading(true)

      if (property==='kapacita' && newValue < 1 ) {
        return;
      }

      fetch('/api/changeInCup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({ cupId: id, updatedProperty: property, newValue, teamId })
      })
        .then(response => response.text())
        .then(data => {
          if (data === 'success') {
            console.log('Cup detail changed successfully');
            setCupData(prevRows => {
              const updatedRows = prevRows.map(row => {
                if (row.id === id) {
                  if (property === 'tími') {
                    const updatedTeams = row[property].map(team => {
                      if (team.id === teamId) {
                        return { ...team, name: newValue };
                      }
                      return team;
                    });
                    return { ...row, [property]: updatedTeams };
                  } else {
                    return { ...row, [property]: newValue };
                  }
                }
                return row;
              });
              return updatedRows;
            });

  
            setLoading(false)
          } else {
            console.log('Failed to change cup data');
            setLoading(false)
          }
        })
        .catch(error => {
          console.error('Error editing cup data:', error);
          setLoading(false)
        });
    } else {
      console.log('No change detected');
      setLoading(false)
    }
  };

  const iconMapping = {
    Phishing: (props) => <Phishing {...props} />,
  }

  const hasSector2 = Array.isArray(cupData) && cupData.some(item => item.sector2 !== null && item.sector2 !== "");
  
  const handleNotCrossedOutAmount = () => {

    if (numberOfScoredFish === null ) {
      setNotCrossedOutAmount(9999)
    }

    else {
      setNotCrossedOutAmount(numberOfScoredFish)
    }

  }

  useEffect(() => {
    handleNotCrossedOutAmount();
  }, [numberOfScoredFish]);

  console.log('len aby z varningov zmysol ', subscribed)

  const handleExportAsJPG = async () => {
    setOpenRowId(null);
    setPrinting(true);
  
    // Wait for the state to update and re-render
    await new Promise((resolve) => setTimeout(resolve, 0));
  
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL('image/jpeg', 1.0);
  
    setImageUrl(imgData);
    setOpenImage(true);
    setPrinting(false);
  };
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page with smooth behavior
  }, []); 

  const deleteNote = (noteId) => {
    fetch('/api/deleteNote', {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({'noteId': noteId})
    })
    .then(response => response.json())
    .then(({result}) => {
      
      if (result === 'ok') { 
        setNotes2(prevNotes => prevNotes.filter(note => note.id !== noteId));
      }

    });
  };

  const fetchData = useCallback(() => {
    fetch('/api/cupData', {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({'cupId': cupId})
    })
    .then(response => response.json())
    .then(({data, owner, subscribed, cupName, numberOfScoredFish, notes}) => {
      setCupData(data);
      setOwner(owner);
      setCupName(cupName);
      setSubscribed(subscribed);
      setNotes2(notes)
      setNumberOfScoredFish(numberOfScoredFish);
      console.log('tieto data fetchujem', data);
      console.log('numberOfScoredFish ',numberOfScoredFish)
      setLoading(false)
    });
  }, [cupId]);



  const openSnackFunction = (msg, severityValue) => {

    setSeverity(severityValue)
    setSnackMessage(msg);
    setOpenSnack(true);
  
  }

  const handleCloseSnack = () => {
    setOpenSnack(false);
    setSnackMessage('');
    fetchNotifications();
  };

  const handleClose = () => {

    setOpen(false);
    fetchData();
    
    };

  useEffect(() => {

    fetchData();

      // Set up interval to call fetchData every 2 minutes
    const intervalId = setInterval(() => {
      fetchData();
      fetchNotifications();
    }, 0.5 * 60 * 1000); // 2 minutes in milliseconds

    // Clean up interval when the component unmounts
    return () => clearInterval(intervalId);

  }, [cupId, fetchData, fetchNotifications]);


  const handleRowClick = (rowId) => {
    setOpenRowId(openRowId === rowId ? null : rowId);
  };


    if (cupData === null) {
      return (          
        <Box
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999, // Ensures it appears on top of other elements
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color="secondary" />
        </Box>
      )
    }

  //najvacsi ulovok - filtruj
  const getTeamWithHighestWeight = () => {
    let highestWeight = 0;
    let highestWeightTeam = null;

    cupData.forEach(team => {
      team.catches.forEach(catchData => {
        if (catchData.weight > highestWeight) {
          highestWeight = catchData.weight;
          highestWeightTeam = team.name;
        }
      });
    });

    return {highestWeightTeam, highestWeight}
  };

  const { highestWeightTeam, highestWeight } = getTeamWithHighestWeight();

  return (
<Box  ref={printRef} style={ (token && !printing) ? { maxWidth: '100%', overflowX: 'auto', paddingBottom: '60px'} : { maxWidth: '100%', overflowX: 'auto' } }>        
{ !printing &&(
<Typography sx={{color: theme.palette.secondary.main, padding: '0 0 10px 0', fontWeight: 600, fontSize: '12px', marginTop: {sx:'0px', sm:'10px'}}}>-- {cupName} --</Typography>
)}
<TableContainer  component={Paper} sx={{  margin: '0 auto', maxWidth: maxWidthSetting, paddingBottom: '20px', }}>
        { printing &&(
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{padding:'0 10px'}}>
          <Typography variant='h7' sx={{color: theme.palette.secondary.main, padding: '0 10px', fontWeight: 600, fontSize: '0.875rem'}}>{cupName}</Typography>
          <Stack direction='row' alignItems='center' justifyContent='flex-end' sx={{padding:'0 10px'}}>
            <img src="/kaprik.png" width="40" height='40' alt="logo"></img>
            <Typography 
              variant="h3" 
              component="div" 
              sx={{
                flexGrow: 1,
                fontSize: '0.875rem',    // Set the font size
                fontWeight: 600,         // Set the font weight
                lineHeight: '1.334em',   // Set the line height
                textAlign: 'left',        // Set text alignment
                color: theme.palette.secondary.main,
                maxWidth: "8em"
              }}
              >
              KAPRIK.SK
            </Typography>
          </Stack>
        </Stack>
        )}
        <Snackbar
          open={openSnack}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          /*TransitionComponent={(props) => <Slide {...props} direction="down" />}*/
          onClose={handleCloseSnack}
          autoHideDuration={2000}
          >
            <Alert
              onClose={handleCloseSnack}
              severity={severity}
              variant="filled"
              sx={{ width: '100%' }}
            >
              {snackMessage}
            </Alert>
          </Snackbar>
        <Table aria-label="collapsible table" >
            <TableHead>
            <TableRow>
                <TableCellStyled align="center">#</TableCellStyled>
                <TableCellStyled align="left">Team</TableCellStyled>
                {hasSector2? (
                <TableCellStyled align="left">1p</TableCellStyled>
                ):(
                  editMode? (
                    <TableCellStyled align="left">1p</TableCellStyled>
                  ) : 
                  (
                  <TableCellStyled align="left">Lov.mies.</TableCellStyled>

                  )
                
                )}
                {editMode && (
                <TableCellStyled align="left"></TableCellStyled>
                )}
                {(hasSector2 || editMode) && (<TableCellStyled align="left">2p</TableCellStyled>
                )}
                {editMode && (
                <TableCellStyled align="left"></TableCellStyled>
                )}
                {notCrossedOutAmount <= 5 ?(
                <TableCellStyled align="center">Úlovky</TableCellStyled>
                ) : (
                  <TableCellStyled align="center">Počet</TableCellStyled>
                )}
                <TableCellStyled align="center">SUM</TableCellStyled>
            </TableRow>
            </TableHead>
            <TableBody>
            {cupData.map((team, index) => (
                <Row 
                changeTeamName={changeTeamName}
                row={team}
                key={team.id} 
                index={index}
                isOpen={openRowId === team.id}
                onRowClick={() => handleRowClick(team.id)}
                fetchData={fetchData}
                editMode={editMode}
                setCupData={setCupData}
                cupId= {team.cupId}
                openSnackFunction={openSnackFunction}
                owner={owner}
                hasSector2={hasSector2}
                notCrossedOutAmount={notCrossedOutAmount}
                sx={{width: '100vw'}}
               />
            ))}
            </TableBody>
        </Table>
        {/*<Box align='center'>
          { !token?  (
            <ButtonBase>
              <Link to='/SignIn' style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                <Stack flexDirection='row' alignItems='center' sx={{ padding: '10px'}}>
                  <BookmarkAdd style={{width: '20px', color: theme.palette.primary.main, padding: '0'}}/> 
                  <Typography noWrap fontSize={12}>Sleduj pretek</Typography>
                </Stack>
              </Link>
            </ButtonBase>
            ) : ( 
              subscribed? (

            <ButtonBase>
              <Stack flexDirection='row' alignItems='center' sx={{ padding: '10px'}}>
                <BookmarkAdd style={{width: '20px', color: theme.palette.primary.main, padding: '0'}}/> 
                <Typography noWrap fontSize={12}>Sleduj pretek</Typography>
              </Stack>
            </ButtonBase>
              ) : (
            <ButtonBase>
              <Stack flexDirection='row' alignItems='center' sx={{ padding: '10px' }}>
                <BookmarkRemove style={{width: '20px', color: theme.palette.primary.main, padding: '0'}}/> 
                <Typography noWrap fontSize={12}>Zruš sledovanie</Typography>
              </Stack>
            </ButtonBase>
            )
            )}

          </Box>*/}
          {notCrossedOutAmount >= 1 && notCrossedOutAmount <= 5 && (
          <Stack flexDirection='row'  alignItems='center' justifyContent='space-between' useFlexGap sx={{maxWidth:'350px'}}>
            <Box sx={{display: 'inline-flex', alignItems: 'center', textAlign: 'center', padding: '10px 10px 0 10px' }}>
              <LooksOneTwoTone sx={{width: '14px', height: '14px',  color: theme.palette.success.main}}/><Typography noWrap fontSize={12}> - bodovaná ryba</Typography>
            </Box>
            <Box sx={{display: 'inline-flex', alignItems: 'center', textAlign: 'center', padding: '10px 10px 0 10px',}}>
              <LooksOneTwoTone sx={{width: '14px',  color: theme.palette.primary.main}}/><Typography noWrap fontSize={12}> - ryba nad 15kg</Typography>
              </Box>
            <Box sx={{display: 'inline-flex', alignItems: 'center', textAlign: 'center',  padding: '10px 10px 0 10px',}}>
              <LooksOneTwoTone sx={{width: '14px', color: theme.palette.secondary.main}}/><Typography noWrap fontSize={12}> - ryba nad 20kg</Typography>
            </Box>
          </Stack>
          )}
          <Stack flexDirection='row'  alignItems='center' justifyContent='space-between' flexWrap="wrap" >
            <Stack flexDirection='row'  alignItems='center' padding={'5px 10px'}>
              < EmojiEventsTwoTone sx={{width: '16px' , color: theme.palette.secondary.main}}/>
              <Typography noWrap fontSize={12}>- Ryba preteku: {highestWeightTeam}, váha: {highestWeight}kg</Typography>
            </Stack>
          </Stack>
          {/*notes.map((note, index) => {
              const IconComponent = iconMapping[note.icon];
              return (
                <Stack key={index} flexDirection="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                  <Stack flexDirection="row" alignItems="center" padding="5px 10px">
                    {IconComponent && (
                      <IconComponent style={{ color: theme.palette.secondary.main, width: '16px' }} />
                    )}
                    <Typography noWrap fontSize={12}>- {note.name}</Typography>
                  </Stack>
                </Stack>
              );
            })*/}
          {notes2 && notes2.map((note, index) => (
            <Stack key={index} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <NoteDisplay note={note['note']} />
              {editMode && (
                <IconButton sx={{marginRight: '30px'}}>
                  <Delete onClick={()=>deleteNote(note.id)} sx={{fontSize: '12px', color: 'lightcoral'}} />
                </IconButton>
              )}
            </Stack>
          ))}
          <Stack flexDirection='row'  alignItems='flex-end' justifyContent='flex-end' style={{ height: '100%', width: '100%' }}>
          { (!printing && !editMode) && (
            <ButtonBase>
              <Print onClick={()=>handleExportAsJPG()} sx={{color: theme.palette.secondary.light, paddingRight: '20px', width: '0.8em'}}/>
            </ButtonBase>
            )
            }
          </Stack>
        { editMode && (
          <NoteCreator fetchData={fetchData} cupId={cupId} />
        )}
        </TableContainer> 
        { (token && (owner || access === 9) && !printing) &&  ( 
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
           <BottomNavigation
              sx={{possition: 'fixed', bottom: '0'}}
              showLabels
            > 
              { (token && (owner || access === 9))   && (
              <BottomNavigationAction  onClick={()=> {setOpen(true); setEditMode(false)}} label="Pridaj Úlovok" icon={<Add />} />
              )}
              { (token && (owner || access === 9) && editMode === false) &&  (
              <BottomNavigationAction onClick={()=> setEditMode(true)} label="Edit Mode On" icon={<Edit />} />
              )}
              { (token && (owner || access === 9) && editMode === true) &&  (
              <BottomNavigationAction   onClick={()=> setEditMode(false)} label="Edit Mode Off" icon={<EditOff  fill='none' />} />
              )}
            </BottomNavigation>
          </Paper>
          )}
          <Modal
              open={open}
              onClose={() => {setOpen(false)}}
              sx={{display: 'flex', flexDirection:'row', justifyContent:'center', maxHeight:{xs:'none', md:'none'}, margin:{md:'20px'}}}
          >
            <Box>
              <AddFishForm openSnackFunction={openSnackFunction} handleClose={handleClose}/>
            </Box>
          </Modal>
          <Modal
              open={openImage}
              onClose={() => {setOpenImage(false)}}
              sx={{display: 'flex', flexDirection:'row', justifyContent:'center', maxHeight:{xs:'none', md:'none'}, margin:{md:'20px'}}}
          >
            <Box sx={{maxWidth: '100vw', height: 'auto', overflow: 'auto' }}>
            <Stack direction={'row'} justifyContent={'flex-end'} bgcolor={'white'} sx={{paddingBottom:'15px'}}>
              <Stack direction={'row'} justifyContent={'center'} sx={{flexGrow: 1}} alignItems={'flex-end'}>
                <Typography variant='h6' sx={{color: theme.palette.primary.main, paddingLeft: '30px'}}>
                  Print Screen
                </Typography>
              </Stack>
              <Close onClick={()=> setOpenImage(false)} sx={{padding: '0.4em'}}></Close>
            </Stack>
            <img src={imageUrl} alt="Screenshot" style={{maxWidth:'100vw'}} />
            </Box>
          </Modal>
      </Box>
    

  )
}

export default Pretek
