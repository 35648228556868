import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import { Badge,  Stack } from '@mui/material';
import { LockOpen, LockPerson, NotificationsOutlined,  } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import settings from './Settings';
import { useEffect, useRef, useState } from 'react';
import Notifications from './Notifications';

function Navbar({fetchNotifications, setNotificationList, setUnseenCount, notificationList, unseenCount}) {





  const tokenJsonString = settings.getToken();
  const tokenObject = JSON.parse(tokenJsonString);
  


  const userIdJsonString = localStorage.getItem('userId');  
  const userIdObject = JSON.parse(userIdJsonString);
  const userId = userIdObject ? userIdObject.userId : null;

  
  /*const notifList = [{'id':1, 'type' : 2,'content': 'Bol pridaný nový pretek <b>Nove Mairgule 2023 Nitra</b>', 'deleteable' : 1}, {'id':2, 'type' : 3,'content': 'Reklamá správa o tom kde a kedy najlepsie pivo majú... ', 'deleteable':2}, {'id':3, 'type' : 1,'content': 'Tím <b>TO je jedno</b> ulovil nový úlovok na súťaži <b>TO JE jedno Dva Krát 2023</b>. Váha: <b>26.33kg</b>', 'deleteable':1  }
  , {'id':4, 'type' : 2,'content': 'Bol pridaný nový pretek <b>Nove Mairgule 2023 Nitra</b>', 'deleteable' : 1}, {'id':5, 'type' : 3,'content': 'Reklamá správa o tom kde a kedy najlepsie pivo majú... ', 'deleteable':2}, {'id':6, 'type' : 1,'content': 'Tím <b>TO je jedno</b> ulovil nový úlovok na súťaži <b>TO JE jedno Dva Krát 2023</b>. Váha: <b>26.33kg</b>', 'deleteable':1  },
  {'id':7, 'type' : 2,'content': 'Bol pridaný nový pretek <b>Nove Mairgule 2023 Nitra</b>', 'deleteable' : 1}, {'id':8, 'type' : 3,'content': 'Reklamá správa o tom kde a kedy najlepsie pivo majú... ', 'deleteable':2}, {'id':9, 'type' : 1,'content': 'Tím <b>TO je jedno</b> ulovil nový úlovok na súťaži <b>TO JE jedno Dva Krát 2023</b>. Váha: <b>26.33kg</b>', 'deleteable':1  }
  ]
*/

  const theme = useTheme();
  const [openNotification ,setOpenNotification] = useState(false)

  const iconRef = useRef(null);


  useEffect(() => {

    fetchNotifications()
    
  }, []);



  const toggleNotification = () => {

    if (!userId) {

      setNotificationList([{'content' : 'Pre zobrazenie notifikácií sa prihláste na odber v  aktuálnych pretekoch.', 'type' : 3}])
    }


    setOpenNotification(prevState => !prevState); // Toggle the notification state

      window.scrollTo({ top: 0, behavior: 'smooth' });

      const seenNotificationIds = notificationList
            .filter(notification => notification.seen === 0)
            .map(notification => notification.id);
            console.log('seenNotifList ',seenNotificationIds);

      if (unseenCount !== 0 && openNotification === true) {

          setUnseenCount(0)
        }


      if (seenNotificationIds.length !== 0 && openNotification === true) {
        
            fetch('/api/seenNotifications', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ seenNotificationIds })
            })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              console.log('Seen notifications sent to the server successfully.');
            })
            .catch(error => {
              console.error('Error sending seen notifications:', error);
            });
        
            const updatedNotificationList = notificationList.map(notification => ({
              ...notification,
              seen: 1 // Update the 'seen' value to 1 for all notifications
            }));
            setNotificationList(updatedNotificationList)
          
          };
  
    };


  const deleteNotification = async (id) => {

    try {
      const response = await fetch('/api/deleteNotification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': token,
        },
        body: JSON.stringify({ 'notificationId': id, 'userId': userId,}),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();

      if (result.response === 'ok') {
        const updatedList = notificationList.filter(notification => notification.id !== id);
        setNotificationList(updatedList);
      }

      if (result.response === 'ok' && id === 'all') {
        const updatedList = notificationList.filter(notification => notification.type === 3);
        setNotificationList(updatedList);
      }
     
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }


  }



  return (
    <Box sx={{ flexGrow: 0, marginBottom: '70px'}}  >
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'white', color: 'black',paddingTop: 1.5, marginLeft: 1.5}}>
        <Toolbar>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Link to={"/"} style={{ textDecoration: 'none' }}>
              <Stack direction='row' alignItems='center'>
                <img src="/kaprik.png" width="40" height='40' alt="logo"></img>
                <Typography 
                  variant="h6" 
                  component="div" 
                  sx={{
                    flexGrow: 1,
                    fontSize: '0.875rem',    // Set the font size
                    fontWeight: 400,         // Set the font weight
                    lineHeight: '1.334em',   // Set the line height
                    textAlign: 'left',        // Set text alignment
                    color: theme.palette.secondary.main
                  }}
                  >
                  <h3>KAPRIK.SK</h3>
                </Typography>
              </Stack>
            </Link>
            <Stack flexDirection='row' alignItems='center'  >
           
            <IconButton
              size="large"
              edge="start"
              aria-label="notifications"
              onClick={()=>toggleNotification()}
              ref={iconRef}
              
            >
              <Link style={{textDecoration: 'none' }}>
                <Badge style={{ marginRight: '1em'}} badgeContent={unseenCount} color="primary">
                  <NotificationsOutlined sx={{ paddingBottom: '0.25em', color: theme.palette.secondary.main }}/>
                </Badge>  
              </Link>
            </IconButton>

            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ mr: 2, color: "#000" }}
            >
              <Link to={"/SignIn"} style={{ textDecoration: 'none', color: theme.palette.secondary.main }}>
                {tokenObject && <LockOpen />
                }
                {!tokenObject && 
                <LockPerson sx={{color: theme.palette.secondary.main}}/>
                }
              </Link>
            </IconButton>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      
        <Notifications
          open={openNotification} 
          setOpenNotification={setOpenNotification} 
          notifList={notificationList} 
          deleteNotification={deleteNotification} 
          toggleNotification={toggleNotification}
          iconRef={iconRef}
        />
    
    </Box>
  )
};

export default Navbar;
