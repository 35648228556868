import { useTheme } from '@emotion/react';
import { ManageAccounts, List, } from '@mui/icons-material'
import { BottomNavigation, BottomNavigationAction,  Paper } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

  // Retrieve token and access from localStorage
  const tokenJsonString = localStorage.getItem('token');
  const accessJsonString = localStorage.getItem('access');
  
  // Parse JSON strings into objects
  const tokenObject = JSON.parse(tokenJsonString);
  const accessObject = JSON.parse(accessJsonString);
  
  // Extract token and access values
  const token = tokenObject ? tokenObject.access_token : null;
  const access = accessObject ? accessObject.access : null;

const BottomNavigationMenu = () => {

  const theme = useTheme()

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: '9999' }} elevation={3}>
        <BottomNavigation
            sx={{possition: 'fixed', bottom: '0'}}
            showLabels> 
            <BottomNavigationAction label="Moje Preteky" icon={<Link to='/MojePreteky'><List sx={{color: theme.palette.secondary.main}}/></Link>} />  
            {(token && access === 9) && (
            
            <BottomNavigationAction label="Admin" icon={<Link to='/AdminMenu'><ManageAccounts sx={{ color: theme.palette.secondary.main }} /></Link>} />
            
            )}
        </BottomNavigation>
    </Paper>
  )
}

export default BottomNavigationMenu
