import { useTheme } from '@emotion/react'
import { Close, Delete, EmojiEvents, ExpandMore, Message, Phishing } from '@mui/icons-material';
import { Avatar,  ButtonBase, Stack, Typography, styled } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';

const NotificationWrapper = styled('div')(({ theme, open }) => ({
    position: 'absolute',
    top: open ? '70px' : '-100%', // Adjust based on the direction you want to slide
    right: 0,
   
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.4)',
    zIndex: theme.zIndex.modal,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
   
    transition: 'top 0.4s ease-in-out', // Smooth transition for sliding effect
    opacity: open ? 1 : 1, // Change opacity for fade effect
    pointerEvents: open ? 'auto' : 'none', // Prevent interaction when hidden
    [theme.breakpoints.up('sm')]: {
        maxWidth: '500px', // 100px max width on small screens
      },
}));

const Notifications = ({open, notifList, deleteNotification, toggleNotification, setOpenNotification, iconRef}) => {

    const theme = useTheme()

    const [visibleCount, setVisibleCount] = useState(3);
    const [visibleExploreMore, setVisibleExploreMore] = useState(false)

    useEffect(() => {
        
        if (!open) {
            setVisibleCount(3);
        }
    }, [open]); 


    const handleMoreClick = () => {
        setVisibleCount(prevCount => prevCount + 4); 
    };

    const filteredList = notifList.slice(0, visibleCount);

    useEffect(() => {
        if (filteredList.length < notifList.length) {
            setVisibleExploreMore(true);
        } else {
            setVisibleExploreMore(false);
        }
    }, [filteredList, notifList]);


    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                wrapperRef.current && 
                !wrapperRef.current.contains(event.target) && 
                iconRef.current && 
                !iconRef.current.contains(event.target)  && 
                open === true
            ) {
                //setOpenNotification(false);
                toggleNotification();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [toggleNotification, iconRef, open]);


    return (
        <NotificationWrapper ref={wrapperRef} theme={theme} open={open}>
            {filteredList.length === 0 ? (
                    <Typography sx={{color: theme.palette.secondary.dark, padding: "20px"}}>Nemáte žiadne notifikácie.</Typography>
            ) : (
                filteredList.map((item, index) => (
                    <div key={index}>
                        <Stack 
                            flexDirection='row' 
                            padding='0.4em' 
                            alignItems='center'
                            justifyContent='space-between' 
                            sx={{ border: `0.1px solid ${theme.palette.primary.dark}`, background: item.seen === 0 ? 'lightblue' : 'white' }}
                        >
                            
                            <Stack flexDirection='row'>
                            <Avatar 
                                sx={{ margin: '0.4em', width: 56, height: 56, backgroundColor: theme.palette.primary.light }}
                            >
                                {item.type === 1 && <Phishing />}
                                {item.type === 2 && <EmojiEvents />}
                                {item.type === 3 && <Message />}
                            </Avatar>
                            { item.type === 1 ? (
                            <Link to={`/Pretek/${item.fk_cup}`} onClick={()=>toggleNotification()} style={{ textDecoration: 'none', color: 'black' }}>
                                <Typography 
                                    textAlign='left' 
                                    ml={2} 
                                    dangerouslySetInnerHTML={{ __html: item.content }} 
                                    sx={{ color: theme.palette.secondary.dark }}
                                />
                             </Link>
                             ) : (
                                
                                <Typography 
                                    textAlign='left' 
                                    ml={2} 
                                    dangerouslySetInnerHTML={{ __html: item.content }} 
                                    sx={{ color: theme.palette.secondary.dark }}
                                />
                               
                             )
                             }
                            </Stack>
                            {item.deleteable === 1 && (
                               
                                <ButtonBase 
                                    onClick={() => deleteNotification(item.id)} 
                                    sx={{ margin: '11px', borderRadius: '50%', display: 'inline-block' }}
                                >
                                    <Delete sx={{ height: '15px', color: theme.palette.secondary.light }} />
                                </ButtonBase>
                            
                            )}
                           
                        </Stack>
                    </div>
                ))
            )}
            {visibleExploreMore ? (
            <>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>  
                <div></div>
                { notifList.some(notification => notification.type != 3) &&(
                <ButtonBase>
                    <Typography p={1} fontSize={12} color={theme.palette.primary.main} onClick={()=> deleteNotification('all')}>Vymaž všetko</Typography>
                </ButtonBase>
                )}
            </Stack>
            <ExpandMore onClick={handleMoreClick} sx={{ padding: '5px' }} />
            </>
            ) : (
                <>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>  
                    <div></div>
                    { notifList.some(notification => notification.type != 3) &&(
                    <ButtonBase>
                        <Typography p={1} fontSize={12} color={theme.palette.primary.main} onClick={()=> deleteNotification('all')}>Vymaž všetko</Typography>
                    </ButtonBase>
                    )}
                </Stack>
                <Close onClick={()=>toggleNotification()} sx={{ padding: '5px' }}/>
                </>
            )}
        </NotificationWrapper>
  )
}

export default Notifications
