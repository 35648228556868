import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

  // Retrieve token and access from localStorage
  const tokenJsonString = localStorage.getItem('token');
  
  // Parse JSON strings into objects
  const tokenObject = JSON.parse(tokenJsonString);
  
  // Extract token and access values
  const token = tokenObject ? tokenObject.access_token : null;

function Preteky() {
    return (
        // Center the Box itself within its parent
        <Box sx={{
            display: 'flex',         // Use flexbox layout
            flexDirection: 'column', // Stack children vertically
            justifyContent: 'center', // Center content horizontally in the flex container
            alignItems: 'center',    // Center content vertically in the flex container
            width: "110%",
            maxHeight: 70,
            height: 70,
            // Set width to 80% of its parent
            margin: 'auto',          // Automatically adjust margin to center the Box
            flexGrow: 1,
            paddingBottom: token ? '60px' : '0px',
            backgroundColor: "rgb(54, 65, 82)",
   
        }}>



<Typography sx={{color: "#FFF"}}> 
info@wizards.sk </Typography>



   

        </Box>
    );
};

export default Preteky;
