import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDuTBGJ8xbsK8gg8QQXQOCq5WyqeQKne_I",
    authDomain: "kaprik-e78a2.firebaseapp.com",
    projectId: "kaprik-e78a2",
    storageBucket: "kaprik-e78a2.appspot.com",
    messagingSenderId: "608996130919",
    appId: "1:608996130919:web:c30059b8f3a44835dd14c2"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { app, messaging };