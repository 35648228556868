import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PhishingTwoToneIcon from '@mui/icons-material/PhishingTwoTone';
import { Link } from 'react-router-dom';
import { Alert, BottomNavigation, BottomNavigationAction, Paper, Snackbar } from '@mui/material';
import {  List, ManageAccounts } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import BottomNavigationMenu from './BottomNavigationMenu'

  // Retrieve token and access from localStorage
  const tokenJsonString = localStorage.getItem('token');
  const accessJsonString = localStorage.getItem('access');
  
  // Parse JSON strings into objects
  const tokenObject = JSON.parse(tokenJsonString);
  const accessObject = JSON.parse(accessJsonString);
  
  // Extract token and access values
  const token = tokenObject ? tokenObject.access_token : null;
  const access = accessObject ? accessObject.access : null;

function Header() {

    const theme = useTheme()
    const [alert, setAlert] = useState(false)
    
    const handleCloseSnack = () => {
        setAlert(false);
      };

 

    const scrollToPreteky = (event) => {
        event.preventDefault(); // Prevent the default anchor behavior
        const element = document.getElementById("preteky");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      };
   
    return (
        // Center the Box itself within its parent
        <Box sx={{
            display: 'flex',         // Use flexbox layout
            flexDirection: 'column', // Stack children vertically
            justifyContent: 'center', // Center content horizontally in the flex container
            alignItems: 'center',    // Center content vertically in the flex container
            width: "100%",
            height: "105vh",           // Set width to 80% of its parent
            margin: 'auto',          // Automatically adjust margin to center the Box
            flexGrow: 1,
            marginTop: -10,
            backgroundImage: "linear-gradient(360deg, rgb(238, 242, 246) 1.09%, rgb(255, 255, 255) 100%)",
     
        }}>
            <h1>
                Body, Ryby, Tabuľky </h1>
            <Typography sx={{ color: "rgb(33, 150, 243);", fontSize: 30, fontWeight: 800, marginTop: -2 }}>

                Kaprik.sk

            </Typography>
            <Typography sx={{ color: "color: rgb(54, 65, 82);" }}>
                Aplikácia na sledovanie rybárskych pretekov naživo.
            </Typography>
            <Stack spacing={2} direction="row" sx={{ marginTop: 5 }}>
                <Button onClick={scrollToPreteky} variant="contained" color="secondary" startIcon={<PhishingTwoToneIcon />} sx={{ textTransform: 'none', fontWeight: 600 }} size="large">Aktuálne</Button>
                {token && access > 0? (
                        <>
                        <Link to='/PridajPretek'>
                            <Button variant="text" sx={{ textTransform: 'none', fontWeight: 600 }}>Pridaj Pretek</Button>
                        </Link>
                        
                        </>
                    ) : (

                        token? (
                            <Button variant="text" onClick={setAlert} sx={{ textTransform: 'none', fontWeight: 600 }}>Pridaj Pretek</Button>                    
                        ) : (
                            <Link to='/SignIn'>
                                <Button variant="text" sx={{ textTransform: 'none', fontWeight: 600 }}>Pridaj Pretek</Button>
                            </Link>
                        
                        )  
                        

                    )}
            </Stack>
            {(token && access > 0) && (
              <BottomNavigationMenu/>
          )}
        <Snackbar
          open={alert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          /*TransitionComponent={(props) => <Slide {...props} direction="down" />}*/
          onClose={handleCloseSnack}
          autoHideDuration={2000}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="error"
              variant="filled"
              sx={{ width: '100%' }}
            >
             Chýba oprávnenie.
            </Alert>
          </Snackbar>

        </Box>
    );
};

export default Header;
