import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { Dialog, DialogTitle, DialogContent, Grid, Box, Typography } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import settings from './Settings';

  // Retrieve token and access from localStorage
  const tokenJsonString = settings.getToken();
  // Parse JSON strings into objects
  const tokenObject = JSON.parse(tokenJsonString);  
  // Extract token and access values
  const token = tokenObject ? tokenObject.access_token : null;

const NoteCreator = ({cupId, fetchData}) => {
  const [note, setNote] = useState('');
  const [iconPickerOpen, setIconPickerOpen] = useState(false);
  const [max1000, setMax1000] = useState(false)

  const theme = useTheme()

  // Define the accessible icons within the component
  const accessibleIcons = [
    'EmojiEmotions',
    'Favorite',
    'Home',
    'Star',
    'CheckCircle',
    // Add more icon names as needed
  ];

  const handleIconClick = (iconName) => {
    setNote(note + `:${iconName}:`); // Adding icon identifier to the text
    setIconPickerOpen(false);
  };

  const handleSaveNote = (cupId) => {

    if (note.length > 1000) {
      setMax1000(true)
      return
    } 

    fetch('/api/saveNote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({ note, cupId }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Save response:', data);
        fetchData()
        setNote('');
        // Optionally handle success response (e.g., show notification)
      })
      .catch(error => {
        console.error('Error saving note:', error);
        // Optionally handle error (e.g., show error message)
      })
      .finally(() => {
        console.log('finally')
      });

    console.log('Note saved:', note);
    
  };


  return (
    <div>
      <Box position="relative" width="100%">
        <TextField
          label={<Typography sx={{fontSize:'0.8em'}}>"Napíš poznámku (max. 1000 znakov)</Typography>}
          multiline
          sx={{marginTop:"20px"}}
          rows={3}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          variant="outlined"
          fullWidth
          helperText={ max1000 && (<Typography sx={{fontSize:'1em'}} color={'red'}>Príliš veľa znakov. Max 1000.</Typography>)}
        />
        <Box
          position="absolute"
          bottom="5px"
          right="10px"
          fontSize="12px"
          color="gray"
        >
          {note.length} / 1000
        </Box>
      </Box>
      <Box pt={1}>
        <IconButton onClick={() => setIconPickerOpen(true)}>
            <EmojiEmotionsIcon sx={{color: theme.palette.secondary.main}}/>
        </IconButton>
        <Button onClick={()=>handleSaveNote(cupId)} variant="contained" color="secondary" size='small'>
            Uložiť
        </Button>
      </Box>

      <Dialog  open={iconPickerOpen} onClose={() => setIconPickerOpen(false)}>
        <DialogTitle sx={{color: theme.palette.secondary.main}}>Zvoľ ikonu:</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {accessibleIcons.map((iconName) => {
              const IconComponent = Icons[iconName];
              if (!IconComponent) {
                console.error(`Icon ${iconName} not found`);
                return null;
              }
              return (
                <Grid item key={iconName} >
                  <IconButton  onClick={() => handleIconClick(iconName)}>
                    <IconComponent sx={{color: theme.palette.secondary.main}}/>
                  </IconButton>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NoteCreator;