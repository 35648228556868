import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import * as React from 'react';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import settings from './Settings';
import { Avatar, Button, ButtonBase, InputAdornment, TextField } from '@mui/material';
import { AccountCircle, AllInclusive, BookmarkAdd, BookmarkRemove, Search, SetMeal } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';

function Preteky({fetchNotifications, handleUserIdUpdate}) {

    const tokenJsonString = settings.getToken();
    const tokenObject = JSON.parse(tokenJsonString);
    const token = tokenObject ? tokenObject.access_token : null;

    const userIdJsonString = localStorage.getItem('userId');  
    const userIdObject = JSON.parse(userIdJsonString);
    const userId = userIdObject ? userIdObject.userId : null;
  
    const theme = useTheme()
    const {maxWidthSetting} = settings
    const [mainData, setMainData] = useState();
    const [subscribed, setSubscribed] = useState(false);
    const toShow = 5
    const [amountToShow, setAmountToShow] = useState(toShow)
    const [onSearch, setOnSearch] = useState(false)

    const today = new Date();
    const [filteredData, setFilteredData] = useState([]);

    const showMore = () => {
      setAmountToShow(prevAmountToShow => {
        const newAmount = prevAmountToShow + toShow;
        return newAmount;
      });
    };

    const showLess = () => {
      setAmountToShow(toShow);
    };

    useEffect(() => {
      if (mainData && mainData.length > 0 && !onSearch) {
        const reducedData = {
          mainData: mainData.slice(0, amountToShow),
        };
        setFilteredData(reducedData.mainData);
      }
    }, [amountToShow, mainData, onSearch]);

    const normalizeString = (str) => {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    };
    
    
    const handleSearch = (event) => {
      const searchText = normalizeString(event.target.value);
    
      if (searchText !== '') {
        const searchWords = searchText.split(' ');
    
        const newData = mainData.filter(item => {
          return searchWords.every(word => 
            normalizeString(item.country).includes(word) ||
            normalizeString(item.location).includes(word) ||
            normalizeString(item.name).includes(word) ||
            normalizeString(item.from).includes(word) ||
            normalizeString(item.to).includes(word) ||
            normalizeString(item.monthTo).includes(word) ||
            normalizeString(item.monthFrom).includes(word) ||
            normalizeString(item.type).includes(word)
          );
        });
    
        setFilteredData(newData);
        console.log('set search data', newData);
        setOnSearch(true);
      } else {
        setOnSearch(false);
      }
    };


    
    useEffect(() => {
        fetch('/api/main', {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/json',
              'userId': userId
            },
          })
          .then(res => res.json())
          .then(({cups, subscribed}) => {
          setMainData(cups);
          setFilteredData(cups);
          setSubscribed(subscribed)
          console.log('preteky, ', cups)
         
        });
      }, [userId]);

      const getDeviceId = () => {
        let deviceId = localStorage.getItem('deviceId');
      
        if (!deviceId) {
          deviceId = uuidv4();
          localStorage.setItem('deviceId', deviceId);
        }
      
        return deviceId;
      }

      const setUnsignedSubscription = async (cupId, situation, cupName) => {

        const deviceId = getDeviceId()

        console.log('deviceId ', deviceId)

        try {
          const response = await fetch('/api/unsignedSubscription', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'cupId': cupId, 'deviceId':deviceId, 'situation':situation, 'cupName':cupName }),
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          const result = await response.json();

          const userId = result.unsignedUserId;

          const userIdObject = { userId: userId };
          const userIdJsonString = JSON.stringify(userIdObject);
          localStorage.setItem('userId', userIdJsonString);


          if (response.ok) {
            if (situation === 'subscribe') {

            setSubscribed(previousValues => [...previousValues, cupId]);
            console.log('setujem', subscribed)
            fetchNotifications();
            handleUserIdUpdate();

          } else {

              setSubscribed(previousValues => previousValues.filter(id => id !== cupId));
              console.log('desetujem', subscribed)
              fetchNotifications();
              
              }
          }

         

        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }


      }

      
      const subscribtion = async (cupId, userId, situation, cupName) => {
        try {
          const response = await fetch('/api/subscription', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
            body: JSON.stringify({ 'cupId': cupId, 'userId': userId, 'situation': situation, 'cupName':cupName }),
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          const result = await response.json();
         
          if (result.response === 'ok') {
                if (situation === 'subscribe') {

                setSubscribed(previousValues => [...previousValues, cupId]);
                fetchNotifications();

            } else {

                setSubscribed(previousValues => previousValues.filter(id => id !== cupId));
                fetchNotifications();
                
                }
            }
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      };

      return (
        // Center the Box itself within its parent
        <Box sx={{
          display: 'flex',         // Use flexbox layout
          flexDirection: 'column', // Stack children vertically
          justifyContent: 'flex-start', // Center content horizontally in the flex container
          alignItems: 'center',    // Center content vertically in the flex container
          minHeight: '100vh',
          width: "100%",
          margin: 'auto',          // Automatically adjust margin to center the Box
          flexGrow: 1,
          backgroundImage: "linear-gradient(360deg, rgb(238, 242, 246) 1.09%, rgb(255, 255, 255) 100%)",
          paddingTop: 5
        }}>
          <div id='preteky'></div>
    
          <EmojiEventsTwoToneIcon sx={{ fontSize: 50, marginBottom: 3 }} color="secondary" />
          <TextField 
            sx={{marginBottom: '1em', marginTop: '1em',width: "87vw", maxWidth: `calc(${maxWidthSetting} - 10%)` }}  
            /*label="Hľadaj pretek"*/ 
            variant="outlined" 
            size="small"
            placeholder='meno, mesiac, lokalita, typ'
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            />
    
          {filteredData ? (
            filteredData.map((item, index) => {
              const itemDateParts = item.to.split('.');
              const itemDate = new Date(`${itemDateParts[2]}-${itemDateParts[1]}-${itemDateParts[0]}`);
        
              return (
                
                <Card key={index} variant="outlined" sx={{ width: "87vw", maxWidth: `calc(${maxWidthSetting} - 10%)`, margin: "0 auto", marginBottom: 2 }}>
                  <Box align='right'>
                  {!token && itemDate > today ? (
                    !userId ? (
                      // Case: No token, no userId
                      <ButtonBase onClick={() => { setUnsignedSubscription(item.id, 'subscribe') }}>
                        <Stack flexDirection='row' alignItems='center' sx={{ padding: '10px' }}>
                          <Typography noWrap fontSize={12}>Sleduj pretek</Typography>
                          <BookmarkAdd style={{ width: '20px', color: theme.palette.secondary.main, padding: '0' }} />
                        </Stack>
                      </ButtonBase>
                    ) : (
                      // Case: No token, but we have userId
                      !subscribed.includes(item.id) ? (
                        <ButtonBase onClick={() => { setUnsignedSubscription(item.id, 'subscribe', item.name) }}>
                          <Stack flexDirection='row' alignItems='center' sx={{ padding: '10px' }}>
                            <Typography noWrap fontSize={12}>Sleduj pretek</Typography>
                            <BookmarkAdd style={{ width: '20px', color: theme.palette.secondary.main, padding: '0' }} />
                          </Stack>
                        </ButtonBase>
                      ) : (
                        <ButtonBase onClick={() => { setUnsignedSubscription(item.id, 'unsubscribe', item.name) }}>
                          <Stack flexDirection='row' alignItems='center' sx={{ padding: '10px' }}>
                            <Typography noWrap fontSize={12}>Zruš sledovanie</Typography>
                            <BookmarkRemove style={{ width: '20px', color: theme.palette.secondary.main, padding: '0' }} />
                          </Stack>
                        </ButtonBase>
                      )
                    )
                  ) : (
                    // Case: We have token, and itemDate > today
                    itemDate > today && (
                      !subscribed.includes(item.id) ? (
                        <ButtonBase onClick={() => subscribtion(item.id, userId, 'subscribe', item.name)}>
                          <Stack flexDirection='row' alignItems='center' sx={{ padding: '10px' }}>
                            <Typography noWrap fontSize={12}>Sleduj pretek</Typography>
                            <BookmarkAdd style={{ width: '20px', color: theme.palette.secondary.main, padding: '0' }} />
                          </Stack>
                        </ButtonBase>
                      ) : (
                        <ButtonBase onClick={() => subscribtion(item.id, userId, 'unsubscribe', item.name)}>
                          <Stack flexDirection='row' alignItems='center' sx={{ padding: '10px' }}>
                            <Typography noWrap fontSize={12}>Zruš sledovanie</Typography>
                            <BookmarkRemove style={{ width: '20px', color: theme.palette.secondary.main, padding: '0' }} />
                          </Stack>
                        </ButtonBase>
                      )
                    )
                  )}
                  </Box>
                  <Link key={item.id} to={`/Pretek/${item.id}`} style={{ textDecoration: 'none', color: 'black' }}>
                    <Box sx={{ p: 2 }}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography gutterBottom variant="h5" component="div">
                          {item.name}
                        </Typography>
                      </Stack>
                      <Typography color="text.secondary" variant="body2">
                        {item.from + ' - ' + item.to}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2 }}>
                      <Stack direction="row" spacing={1}>
                        <Chip label={item.fee + ' eur'} color="secondary" size="small" />
                        <Chip
                          deleteIcon={<SetMeal />}
                          onDelete={() => {}}
                          label={item.numberOfScoredFish > 20 ? <AllInclusive sx={{fontSize:'1em'}}/> : item.numberOfScoredFish}
                          size="small"
                        />                        
                        <Chip label={item.location} size="small" />
                        <Chip label={item.enroled + '/' + item.capacity} size="small" />
                      </Stack>
                    </Box>
                  </Link>
                </Card>
              );
            })
          ) : (
            <p>Žiadne súťaže</p>
          )}
          { !onSearch && (
          <Stack direction={'row'} pb={3} spacing={2}>
            {mainData && amountToShow < mainData.length && (
              <Button sx={{minWidth:'100px', fontWeight: 'bold',textTransform: 'none'}} color='secondary' variant="text" onClick={showMore}>Viac</Button>
            )}  
            {mainData && (amountToShow >= mainData.length || amountToShow > toShow ) && (
              <Button sx={{minWidth:'100px', fontWeight: 'bold', textTransform: 'none'}} color='primary' variant="text"  onClick={showLess} >Menej</Button>
            )}
          </Stack>
          )}  
        </Box>
      );
    };

export default Preteky;
