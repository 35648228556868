import { useTheme } from '@emotion/react';
import { Alert, Box, Button, FormControl, FormHelperText, Snackbar, TextField, Typography } from '@mui/material';
import React, { useRef, useState } from 'react'
import BottomNavigationMenu from './BottomNavigationMenu';


const Admin = () => {

  const tokenData = JSON.parse(localStorage.getItem('token') || '{}');
  const token = tokenData.access_token || '';
  const [openSnack, setOpenSnack] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')
  const [severity, setSeverity] = useState('')

  const handleCloseSnack = () => {
    setOpenSnack(false);
    setSnackMessage('');
  };

  const handleOpenSnack = (message, severity) => {
    setOpenSnack(true);
    setSnackMessage(message)
    setSeverity(severity)
  };

    const [formErrors, setFormErrors] = useState({
        name: false,
        password: false,
        passwordAgain: false,
        match: false
      });
  

    const theme = useTheme()
    const formRef = useRef(null);


const handleSubmit = (event) => {
  event.preventDefault();
  const formData = new FormData(event.currentTarget);
  
  const userName = formData.get('userName');
  const password = formData.get('password');
  const passwordAgain = formData.get('passwordAgain');

  console.log({
    userName: formData.get('userName'),
    password: formData.get('passwordAgain'),
  });
  
  const passwordsMatch = password === passwordAgain;

  setFormErrors({
    name: !userName,
    password: !password,
    passwordAgain: !passwordAgain,
    match: !passwordsMatch,
  });

    // If there are errors, prevent form submission or handle accordingly
    if (!userName || !password || !passwordAgain || !passwordsMatch) {
      return; 
    }

    fetch('/api/newOrganiser', {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': token
      },
      })
      .then(response => response.text())
      .then(data => {
        if (data === 'success') {
          console.log('User added sussessfully on server side');
          formRef.current.reset();
          handleOpenSnack('Úživateľ úspešne vytvorený.', 'success')
          
          
        } 
        
        else if (data === 'alreadyExist') {
            console.log('already exists')
            
            handleOpenSnack('Úživateľ už existuje.', 'error')
        }
        
        else {
          console.log('Failed to submit on server side');
  
        }
      })
               

    .catch(error => {
          console.log('error', error)
      });

      console.log('Form submitted!');

}




    return (
        <Box
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: 'calc(100vh - 140px)'
          }}
        >
          <Typography component="h1" variant="h5" sx={{color:theme.palette.primary.main}}>
            Nový Organizátor
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} ref={formRef}>
            <Box>
            <FormControl fullWidth style={{padding:'0'}}>
                <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Meno"
                name="userName"
                autoComplete="name"
                autoFocus
                />
                <FormHelperText sx={{color:'red'}}>{formErrors.name? ('Chýba') : ('')}</FormHelperText>
            </FormControl>

            <FormControl fullWidth style={{padding:'0'}}>
                <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Heslo"
                type="password"
                id="password"
                />
                <FormHelperText sx={{color:'red'}}>{formErrors.password? ('Chýba') : ('')}</FormHelperText>
            </FormControl>
            <FormControl fullWidth style={{padding:'0'}}>
                <TextField
                margin="normal"
                required
                fullWidth
                name="passwordAgain"
                label="Zopakuj Heslo"
                type="password"
                id="passwordAgain"
                />
                <FormHelperText sx={{color:'red'}}>{formErrors.passwordAgain? ('Chýba') : ('')}</FormHelperText>
                <FormHelperText sx={{color:'red'}}>{formErrors.match? ('Heslo sa nezhoduje') : ('')}</FormHelperText>

            </FormControl>

      
            <Button

            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            >
              Uložiť
            </Button>
            </Box>    
          </Box>
          <Snackbar
          open={openSnack}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          /*TransitionComponent={(props) => <Slide {...props} direction="down" />}*/
          onClose={handleCloseSnack}
          autoHideDuration={2000}
          >
            <Alert
              onClose={handleCloseSnack}
              severity={severity}
              variant="filled"
              sx={{ width: '100%' }}
            >
              {snackMessage}
            </Alert>
          </Snackbar>
          <BottomNavigationMenu/>
        </Box>

  );
}

export default Admin
